import { Upload } from "antd";
import BaseService from "../core/base-service";
import StorageService, { StorageItems } from "../core/storage-service";

export default class TechService extends BaseService {
  // TODO: change this to your route in server
  static route = "tech";
  private storage: StorageService = new StorageService();


  async getTechNews(pageNo = 1) {
    const url = `/api/news/patent`;
    const formData = {
      page: pageNo,
      size: 10,
      name: "政策资讯"
    };
    const response = await this.httpService.post<any, any>(url, formData);
    return response;
  }
  async focusNews(focusId:string) {
    const url = `/api/c/news/${focusId}/focus`;
    const response = await this.httpService.post<any, any>(url, {});
    return response;
  }

  /*专利列表接口*/
  async getTechData(pageNo = 1, title?: string, area?: string, law?: string, industry?: string, org?: string) {
    const url = `/api/third/patent/findpats`;
    const formData = {
      curr: pageNo,
      limit: 10,
    };

    title ? ((formData as any)["keys"] = title) : void 0;
    area ? ((formData as any)["district"] = area) : void 0;
    industry ? ((formData as any)["industry"] = industry) : void 0;
    org ? ((formData as any)["organ"] = org) : void 0;
    law ? ((formData as any)["law"] = law) : void 0;
    const response = await this.httpService.post<any, any>(url, formData);
    return response;
  }

  async getTechDetailData(uid?: string, pid?: string, user?: any) {
	  console.log(user)
    const url = `/api/third/patent/getDetails`;
    const formData = {
      id:pid,
      un_id: uid,
      phone: process.env.NODE_ENV === "development" ? "+86.13023093179" : user.phone
    };
    const response = await this.httpService.post<any, any>(url, formData);
    return response;
  }

  /*报名接口*/
  async postRegistrationForm(formData: any): Promise<any> {
    const response = await this.httpService.post<any, any>(
      `/api/c/patent/works/create`,
      formData
    );
    return response;
  }

  async login(email: string, password: string): Promise<any> {
    const response = await this.httpService.post<any, any>(
      `${this.path}/login`,
      { username: email, password }
    );
    // console.log('response', response)
    return response.data;
  }

  async upload(file: File): Promise<any> {
    const encodeParam = encodeURI(
      Date.now() + (Math.random() * 100).toFixed(0) + "_" + file.name
    );
    const url = `/misc/upload?bucketName=techdeal&pathName=%2fpatent%2f${encodeParam}`;
    let formdata = new FormData();
    formdata.set("file", file);
    const response = await this.httpService.upload<any, any>(url, formdata);
    console.log("上传结果", response);
    return response;
  }

  async register(email: string, password: string): Promise<any> {
    const response = await this.httpService.post<any, any>(
      `${this.path}/register`,
      { username: email, password }
    );

    return response.data;
  }

  async getOnlineActivity(pageNo = 1) {

    const url = `/api/activities/online?page=${pageNo}&size=10&publishPlatforms=003`;
    const response = await this.httpService.get<any>(url);
    return response;
  }
  async getOfflineActivity(pageNo = 1) {

    const url = `/api/activities/offline?page=${pageNo}&size=10&publishPlatforms=003`;
    const response = await this.httpService.get<any>(url);
    return response;
  }

  async getActivityDetail(id:string , type:string) {
    const url = `/api/activities/${type}/${id}`;
    const response = await this.httpService.get<any>(url);
    return response;
  }

  async focusActivity(id:string) {
    const url = `/api/c/activities/${id}/focus`;
    const response = await this.httpService.post(url);
    return response;
  }
  async unfocusActivity(id:string) {
    const url = `/api/c/activities/${id}/focus`;
    const response = await this.httpService.delete(url);
    return response;
  }

  /** 关注活动列表 */
  async getFocusActivityList() {
    const url = `/api/c/activities/focus`;
    const response = await this.httpService.get<any>(url);
    return response;
  }
}
