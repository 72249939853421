export enum StorageItems {
  TOKEN = "token",
  GUEST = "guest",
  VCODE = "vcode",
  USER = "user",
}

export default class StorageService {
  setItem(itemName: StorageItems, value: string) {
    localStorage.setItem(itemName, value);
  }

  getItem(itemName: StorageItems): string {
    return localStorage.getItem(itemName) || "";
  }

  clearItem(itemName: StorageItems) {
    localStorage.removeItem(itemName);
  }

  clearAll() {
    localStorage.clear();
  }

  getUser() {
    return localStorage.getItem(StorageItems.USER);
  }

  logout() {
    localStorage.removeItem(StorageItems.USER);
    localStorage.removeItem(StorageItems.GUEST);
    localStorage.removeItem(StorageItems.TOKEN);
  }
}
