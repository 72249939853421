import React, { CSSProperties, useLayoutEffect, useState } from "react";
import { ReactECharts } from "../../echarts/echarts";

function getDefaultOption() {
  return   {
    tooltip: {
      trigger: 'item'
    },
    legend: {
      orient: 'vertical',
      left: 'left'
    },
    series: [
      {
        name: '专利类型',
        type: 'pie',
        radius: ['40%', '70%'],
        avoidLabelOverlap: false,
        itemStyle: {
          borderRadius: 10,
          borderColor: '#fff',
          borderWidth: 2
        },
        label: {
          show: false,
          position: 'center'
        },
        emphasis: {
          label: {
            show: true,
            fontSize: '40',
            fontWeight: 'bold'
          }
        },
        labelLine: {
          show: false
        },
        data: [
          { value: 0, name: '发明专利' },
          { value: 0, name: '实用新型' }
        ]
      }
    ]
  }
}

function getChartOption(params: PieBlockProps) {
  const currentOption = getDefaultOption();
  if(params.fmMap) {
    currentOption.series[0].data[0].value = params.fmMap.count
    currentOption.series[0].data[1].value = params.syMap.count
  }

  return currentOption;
}

const style = {
  width: "100%",
  height: "100%",
};

export interface StackAreaChartProps {
  key: string;
  data: [];
  totalFlag: boolean;
}

export interface PieBlockProps {
  style?: CSSProperties;
  title?: string;
  fmMap: { count: number, percent: number };
  syMap: {count: number, percent: number}
}

const PieChartBlock: React.FC<PieBlockProps> = (props) => {
  const option = getChartOption(props);
  return (
    <div
      className="leftPanel-div-stackAreaBlock dashDashboard-block"
      style={{ ...props.style }}
    >
      <div className="pie-div-title" style={{textAlign: 'center'}}>
        {props.title}
      </div>
      <ReactECharts option={option as any} style={style} />
    </div>
  );
};

export default PieChartBlock;
