/* eslint-disable */
/* tslint:disable */

import dayjs from "dayjs";
import { Layout } from "antd";
import React from "react";

const Footer = () => {
  return (
    <Layout.Footer className="footer">
      <div className="footer-container">
        <div className="footer-info">
          <div className="info-left">
            <div className="info-logo"></div>
            <div className="info-text">
              关于一些企业需求工艺开发， 自动化生产简洁等等，
              食品标准等等关于一些企业需求业需求工艺开发， 自动化生产简洁等等，
              食品标准业需工艺开发...
            </div>
          </div>
          <div className="info-right">
            <div className="info-unit">
              <div>指导单位：</div>
              <div>上海市知识产权局</div>
              <div style={{ marginTop: "20px" }}>运营单位：</div>
              <div>上海技术交易所</div>
            </div>
            <div className="info-nav">
              <div>快捷导航</div>
              <div>关于我们</div>
              <div>交易规则</div>
              <div>交易服务</div>
            </div>
            <div className="info-nav">
              <div>联系我们</div>
              <div>交易热线：4000-211-365</div>
              <div>服务邮箱：Marketing@stte.com</div>
              <div>工作时间：09:00-17:00（工作日）</div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-text">
        Copyright ©2017-2022 上海市专利运营推广平台 版权所有 ICP证：
        沪ICP备18013364号-7 版本：1.0.0
      </div>
    </Layout.Footer>
  );
};

export default Footer;
