/* eslint-disable */
/* tslint:disable */

import React, { useEffect, useState } from "react";
import starIcon from "../../images/icon/home-star.svg";

import SHMap from "../../components/charts/map/shanghai-map";
import { getEnv } from "../../helpers/mobx-easy-wrapper";

const Info = () => {
  const [techList, setTechList] = useState([]);
  let pageNo = 1;
  useEffect(() => {
    function scrollerAnimation() {
      const parent = document.getElementById("techScroller") as HTMLDivElement;
      setInterval(function () {
        if (parent.scrollTop + parent.clientHeight >= parent.scrollHeight) {
          //parent.scrollTop = 0;
        } else {
          parent.scrollTop++;
        }
      }, 40);
    }

    async function fetchData() {
      const { apiFactory } = getEnv();
      const response = await apiFactory.techService.getTechData(pageNo);
      const totalPages = response.count % response.limit === 0 ? response.count / response.limit : Math.floor(response.count / response.limit) + 1;
      if (totalPages === pageNo) {
        pageNo = 0;
      }
      setTechList((prevData) => {
        return prevData.concat(response.data);
      });
    }
    setInterval(() => {
      pageNo++;
      fetchData();
    }, 3000);
    fetchData();
    scrollerAnimation();
  }, []);

  // @ts-ignore
  return (
    <div className="home-div-infoContainer">
      <div className="card-div-container">
        <div className="card-div-title">上海市 申请专利数量</div>
        <div className="card-div-count">1361170</div>
      </div>

      <div className="container__half">
        <div className="infoContainer-div-blockTitle">NEWS</div>
        <div className="infoContainer-div-title">专利信息</div>
        <div className="scroller" id="techScroller">
          {techList.map((item: any, index) => {
            return (
              <dl
                key={`info_${index}`}
                className="news-dl-list"
                onClick={() => {
                  location.href = `patent/detail?pid=${item.PATENT_ID}&uid=${item.UNIVERSITYMSG_ID}`;
                }}
              >
                <dt>
                  <img src={starIcon} />
                  <img src={starIcon} />
                  <img src={starIcon} />
                  <img src={starIcon} />
                  <img src={starIcon} />
                </dt>
                <dd>{item.PATENT_TITLE}</dd>
              </dl>
            );
          })}
        </div>
      </div>

      <div className="container__half shanghai-svgContainer">
        <a href="/data#city">
          <SHMap />
        </a>
      </div>
    </div>
  );
};

export default Info;
