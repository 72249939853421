/* eslint-disable */
/* tslint:disable */

import React from "react";

import SubTitle from "./title/sub-title";
import JuryCard from "./jury-card/jury-card";

const Jury = () => {
  return (
    <div className="home" style={{}}>
      <div className="jury__line outlineTitle">
        <label>评委专家</label>
      </div>
      <div className="subTitle-zh jury__line" style={{ marginBottom: "24px" }}>
        评委A类
      </div>
      <JuryCard />
      <div className="subTitle-zh jury__line" style={{ marginBottom: "24px" }}>
        评委B类
      </div>
      <JuryCard />
    </div>
  );
};

export default Jury;
