/* eslint-disable */
/* tslint:disable */

import React, { CSSProperties } from "react";
import banner from "../../images/banner.png";
import { Carousel } from "antd";

const Banner = () => {
  return (
    <div className="home-div-bannerContainer">
      <Carousel autoplay>
        <div>
          <img src={banner} />
        </div>
        <div>
          <img src={banner} />
        </div>
      </Carousel>
    </div>
  );
};

export default Banner;
