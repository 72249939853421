import React, { useEffect, useState } from "react";
import {
  Alert,
  Form,
  Input,
  Checkbox,
  Button,
  Radio,
  Upload,
  Row,
  Col,
  message,
  notification,
} from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { getEnv } from "../../../helpers/mobx-easy-wrapper";

const Enterprise = () => {
  const [form] = Form.useForm();
  const [detail, setDetail] = useState({});
  const [defaultFileList, setDefaultFileList] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [errorMsg, setErrorMsg] = useState("");
  const [prospectusUrl, setProspectusUrl] = useState("");
  const [countdown, setCountdown] = useState("");

  const { apiFactory } = getEnv();
  const formItemLayout = {
    labelCol: {
      xs: { span: 24 },
      sm: { span: 4 },
    },
    wrapperCol: {
      xs: { span: 24 },
      sm: { span: 20 },
    },
  };
  const formItemLayoutWithOutLabel = {
    wrapperCol: {
      xs: { span: 24, offset: 0 },
      sm: { span: 20, offset: 4 },
    },
  };

  function countTime() {
    //获取当前时间
    var date = new Date();
    var now = date.getTime();
    //设置截止时间
    var str = "2022/5/10 14:30:00";
    var endDate = new Date(str);
    var end = endDate.getTime();

    //时间差
    var leftTime = end - now;
    //定义变量 d,h,m,s保存倒计时的时间
    var d, h, m, s;
    if (leftTime >= 0) {
      d = Math.floor(leftTime / 1000 / 60 / 60 / 24);
      h = Math.floor((leftTime / 1000 / 60 / 60) % 24);
      m = Math.floor((leftTime / 1000 / 60) % 60);
      s = Math.floor((leftTime / 1000) % 60);
    }
    //将0-9的数字前面加上0，例1变为01
    d = checkTime(d);
    h = checkTime(h);
    m = checkTime(m);
    s = checkTime(s);
    function checkTime(i: any) {
      if (i < 10) {
        i = "0" + i;
      }
      return i;
    }
    // console.log()
    setCountdown(`距离报名时间还有${d}天${h}时${m}分`);
    //递归每秒调用countTime方法，显示动态时间效果
    setTimeout(countTime, 1000);
  }

  useEffect(() => {
    countTime();
    async function getDetail() {
      const result = await apiFactory.activityService.getWorksDetail();
      if (result.status == 401) {
        message.error("用户未登录，请先登录");
        (document.getElementById("loginHeader") as HTMLDivElement).click();
        return;
      }
      if (result) {
        result.orgName && (result.orgName = result.orgName.split(","));
        result.orgCategory &&
          (result.orgCategory = result.orgCategory.split(","));
        console.log(result.prospectusUrl);

        if (result.prospectusUrl) {
          // 填充文件名
          const temp = result.prospectusUrl.split("/");
          const fileName = temp[temp.length - 1];

          setDefaultFileList((val) => {
            (val as any).push({
              uid: "1",
              name: fileName,
              status: "done",
              url: result.prospectusUrl,
            });
            return val;
          });
        }
        form.setFieldsValue(result);
        if (result.status === 1) {
          setErrorMsg(result.failReason);
        }
        setDetail(result);
      }
      console.log(result);
    }
    getDetail();
  }, []);

  const onFinish = (values: any) => {
    console.log("Received values of form: ", values);
  };

  const normFile = (e: any) => {
    console.log("Upload event:", e);
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };

  function beforeUpload(file: any) {
    const isLt2M = file.size / 1024 / 1024 < 50;
    if (!isLt2M) {
      message.error("文件大小必须小于50mb!");
    }
    return isLt2M;
  }

  const handleUpload = async (option: any) => {
    const file = option.file as File;

    try {
      const result = await apiFactory.techService.upload(file);
      if (result.status === 200) {
        setProspectusUrl(result.data.downloadUrl);
        option.onSuccess(result.url);
        setDefaultFileList([]);
      } else {
        message.error("文件上传失败");
      }
    } catch (error) {
      message.error("文件上传失败");
      option.onError(error);
    }
  };

  function showTip() {
    notification.info({
      message: `报名未开始 `,
      description: countdown,
      placement: "top",
    });
  }

  const submitForm = async () => {
    //form.submit();
    if (!apiFactory.authService.isAuthenticated()) {
      message.error("用户未登录，请先登录");
      return;
    }
    const values = await form.validateFields();
    console.log("Success:", values);
    const formData = form.getFieldsValue();

    formData.orgName && (formData.orgName = formData.orgName.join(","));
    formData.orgCategory &&
      (formData.orgCategory = formData.orgCategory.join(","));
    formData.prospectusUrl = prospectusUrl;
    formData.type = "企业";
    const finalData = Object.assign(detail, formData);
    const res = await apiFactory.techService.postRegistrationForm(finalData);
    if (res.status == 401) {
      message.error("用户未登录，请先登录");
      return;
    }
    message.success("提交成功");
  };

  return (
    <div className="home">
      {errorMsg && (
        <Alert
          style={{ position: "relative", top: "30px" }}
          message={errorMsg}
          type="error"
          showIcon
          closable
        />
      )}
      <div className="jury__line outlineTitle" style={{ marginLeft: "0px" }}>
        <label>企业组报名</label>
      </div>
      <div className="subTitle-zh" style={{ marginBottom: "48px" }}>
        基本情况
      </div>

      <Form
        name="complex-form"
        form={form}
        onFinish={onFinish}
        labelCol={{ span: 3 }}
        wrapperCol={{ span: 18 }}
        style={{ marginBottom: "68px" }}
      >
        <Form.List
          name="orgName"
          initialValue={[""]}
          rules={[
            {
              validator: async (_, names) => {
                if (!names || names.length < 1) {
                  return Promise.reject(new Error("At least 1 passengers"));
                }
              },
            },
          ]}
        >
          {(fields, { add, remove }, { errors }) => (
            <>
              {fields.map((field, index) => (
                <Form.Item
                  {...formItemLayout}
                  extra={index === 0 ? "*第一单位默认为牵头单位" : ""}
                  required={false}
                  key={field.key}
                >
                  <Form.Item
                    {...field}
                    validateTrigger={["onChange", "onBlur"]}
                    rules={[
                      {
                        required: true,
                        whitespace: true,
                        message: "单位名称不能为空.",
                      },
                    ]}
                    noStyle
                  >
                    <Input
                      placeholder="参赛单位名称"
                      style={{ width: "60%" }}
                    />
                  </Form.Item>
                  {fields.length > 1 ? (
                    <MinusCircleOutlined
                      className="dynamic-delete-button"
                      onClick={() => remove(field.name)}
                    />
                  ) : null}
                </Form.Item>
              ))}
              <Form.Item>
                <Button
                  type="dashed"
                  onClick={() => add()}
                  style={{ width: "60%" }}
                  icon={<PlusOutlined />}
                >
                  添加参赛单位
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>

        {/* 
            <Form.Item extra="*第一单位默认为牵头单位">
              <Form.Item
                name="username"
                noStyle
                rules={[{ required: true, message: "单位名称不能为空" }]}
              >
                <Input style={{ width: 240 }} placeholder="参赛单位名称" />
              </Form.Item>
              {unitNames.length > 1 ? (
                <MinusCircleOutlined
                  className="dynamic-delete-button"
                  onClick={() => remove(index)}
                />
              ) : null}
            </Form.Item> */}

        <Form.Item name="orgCategory" label="单位类型(多选):" labelAlign="left">
          <Checkbox.Group>
            <Row>
              <Checkbox value="企业" style={{ lineHeight: "32px" }}>
                企业
              </Checkbox>
              <Checkbox value="高校" style={{ lineHeight: "32px" }}>
                高校
              </Checkbox>
              <Checkbox value="科研院所" style={{ lineHeight: "32px" }}>
                科研院所
              </Checkbox>
              <Checkbox value="医疗卫生机构" style={{ lineHeight: "32px" }}>
                医疗卫生机构
              </Checkbox>
              <Checkbox value="个人" style={{ lineHeight: "32px" }}>
                个人
              </Checkbox>
            </Row>
          </Checkbox.Group>
        </Form.Item>

        <Form.Item style={{ marginBottom: 0 }}>
          <Form.Item
            name="contactUser"
            validateTrigger={["onChange", "onBlur"]}
            rules={[
              { required: true, whitespace: true, message: "联系人不能为空." },
            ]}
            style={{ display: "inline-block", width: 240 }}
          >
            <Input placeholder="联系人" />
          </Form.Item>
          <Form.Item
            name="contactPhone"
            validateTrigger={["onChange", "onBlur"]}
            rules={[
              { required: true, whitespace: true, message: "电话不能为空." },
            ]}
            style={{ display: "inline-block", width: 240, margin: "0 8px" }}
          >
            <Input placeholder="电话" />
          </Form.Item>
        </Form.Item>
        <Form.Item
          name="worksName"
          validateTrigger={["onChange", "onBlur"]}
          rules={[
            {
              required: true,
              whitespace: true,
              message: "参赛项目名称不能为空.",
            },
          ]}
        >
          <Input style={{ width: 490 }} placeholder="参赛项目名称" />
        </Form.Item>
        <Form.Item name="orgField" label="单位类型(多选):" labelAlign="left">
          <Radio.Group>
            <Row style={{ height: "38px" }}>
              <Radio value="集成电路">集成电路</Radio>
              <Radio value="生物医药">生物医药</Radio>
              <Radio value="人工智能">人工智能</Radio>
              <Radio value="人工智能">人工智能</Radio>
              <Radio value="人工智能">人工智能</Radio>
              <Radio value="集成电路">集成电路</Radio>
              <Radio value="生物医药">生物医药</Radio>
              <Radio value="人工智能">人工智能</Radio>
              <Radio value="人工智能">人工智能</Radio>
              <Radio value="j">
                <Input style={{ width: 140 }} placeholder="其他" />
              </Radio>
            </Row>
          </Radio.Group>
        </Form.Item>
        <div className="subTitle-zh" style={{ marginTop: "68px" }}>
          项目信息
        </div>
        {/* row 1*/}
        <Form.Item name="radio-group">
          <Form.Item noStyle>
            <span style={{ marginRight: "50px" }}>参赛项目基本情况</span>
          </Form.Item>

          <Checkbox.Group>
            <Row style={{ color: "#1e43ad" }}>
              <Checkbox
                prefixCls="ant-radio"
                value="include"
                style={{ color: "#1e43ad" }}
              >
                确认商业计划书已包含此部分
              </Checkbox>
            </Row>
          </Checkbox.Group>
        </Form.Item>

        <Row gutter={[16, 16]}>
          <Col span={"9"}>
            <label className="form-label">
              项目基本情况（内容包括但不限于:立项时间、项目投入、实施过程、商业模式、社会效益、获奖情况、配套资源）
            </label>
          </Col>
          <Col span={"9"} offset={1}>
            <label className="form-label">
              核心团队（至少应当包括但不限于项目研发人员数量、研发经历、研发成果以及项目管理、运营、推广团队的人员情况，参赛项目团队个人经历介绍等）{" "}
            </label>
          </Col>
        </Row>

        <Row gutter={[16, 16]} style={{ marginTop: "15px" }}>
          <Col span={"9"}>
            <Form.Item noStyle name="basicInformation">
              <Input.TextArea rows={4} />
            </Form.Item>
          </Col>

          <Col span={"9"} offset={1}>
            <Form.Item noStyle name="coreTeam">
              <Input.TextArea rows={4} />
            </Form.Item>
          </Col>
        </Row>

        {/* row 2*/}
        <Form.Item name="radio-group2" style={{ marginTop: "25px" }}>
          <Form.Item noStyle>
            <span style={{ marginRight: "50px" }}>参赛项目技术介绍</span>
          </Form.Item>
          <Checkbox.Group>
            <Row style={{ color: "#1e43ad" }}>
              <Checkbox
                prefixCls="ant-radio"
                value="include"
                style={{ color: "#1e43ad" }}
              >
                确认商业计划书已包含此部分
              </Checkbox>
            </Row>
          </Checkbox.Group>
        </Form.Item>

        <Row gutter={[16, 16]}>
          <Col span={"9"}>
            <label className="form-label">
              核心技术介绍（内容至少包括:现有技术背景情况，现有市场情况，本项目技术内容）
            </label>
          </Col>
          <Col span={"9"} offset={1}>
            <label className="form-label">
              技术先进性（内容至少包括:相对同类产品的差异化产品特性（产品特色）、成本优势和客户满意度等情况）
            </label>
          </Col>
        </Row>

        <Row gutter={[16, 16]} style={{ marginTop: "15px" }}>
          <Col span={"9"}>
            <Form.Item noStyle name="coreTechnology">
              <Input.TextArea rows={4} />
            </Form.Item>
          </Col>

          <Col span={"9"} offset={1}>
            <Form.Item noStyle name="advancedTechnology">
              <Input.TextArea rows={4} />
            </Form.Item>
          </Col>
        </Row>

        {/* row 3*/}

        <Row gutter={[16, 16]} style={{ marginTop: "25px" }}>
          <Col span={"9"}>
            <label className="form-label">
              技术壁垒（说明国内外有能力复制该技术的团队数量，主要从国内外同类技术的特征比较、关键技术特点、技术难度等角度来进行说明）
            </label>
          </Col>
          <Col span={"9"} offset={1}>
            <label className="form-label">
              产品或技术成熟度（处于实验室阶段、小批量试产还是量产阶段，产品上市时间或预计上市时间、稳定运行时间、实际使用效果、用户数等）
            </label>
          </Col>
        </Row>

        <Row gutter={[16, 16]} style={{ marginTop: "15px" }}>
          <Col span={"9"}>
            <Form.Item noStyle name="technologyBarrier">
              <Input.TextArea rows={4} />
            </Form.Item>
          </Col>

          <Col span={"9"} offset={1}>
            <Form.Item noStyle name="technologyMaturity">
              <Input.TextArea rows={4} />
            </Form.Item>
          </Col>
        </Row>

        {/* row 4*/}
        <Form.Item name="radio-group" style={{ marginTop: "25px" }}>
          <Form.Item noStyle>
            <span style={{ marginRight: "50px" }}>
              参赛项目进展及市场前景分析
            </span>
          </Form.Item>
          <Checkbox.Group>
            <Row style={{ color: "#1e43ad" }}>
              <Checkbox
                prefixCls="ant-radio"
                value="include"
                style={{ color: "#1e43ad" }}
              >
                确认商业计划书已包含此部分
              </Checkbox>
            </Row>
          </Checkbox.Group>
        </Form.Item>

        <Row gutter={[16, 16]}>
          <Col span={"9"}>
            <label className="form-label">
              市场规模（目标客户、适用场景、国内外市场规模预测等）
            </label>
          </Col>
          <Col span={"9"} offset={1}>
            <label className="form-label">
              近三年收入状况以及未来三年收入预期（成立时间未满三年的企业，按实际存在时间填写）
            </label>
          </Col>
        </Row>

        <Row gutter={[16, 16]} style={{ marginTop: "15px" }}>
          <Col span={"9"}>
            <Form.Item noStyle name="marketSize">
              <Input.TextArea rows={4} />
            </Form.Item>
          </Col>

          <Col span={"9"} offset={1}>
            <Form.Item noStyle name="recentStatus">
              <Input.TextArea rows={4} />
            </Form.Item>
          </Col>
        </Row>

        {/* row 5*/}
        <Form.Item name="radio-group" style={{ marginTop: "25px" }}>
          <Form.Item noStyle>
            <span style={{ marginRight: "50px" }}>
              参赛项目专利培育布局现状
            </span>
          </Form.Item>
          <Checkbox.Group>
            <Row style={{ color: "#1e43ad" }}>
              <Checkbox
                prefixCls="ant-radio"
                value="include"
                style={{ color: "#1e43ad" }}
              >
                确认商业计划书已包含此部分
              </Checkbox>
            </Row>
          </Checkbox.Group>
        </Form.Item>

        <Row gutter={[16, 16]}>
          <Col span={"9"}>
            <label className="form-label">
              与本项目技术相关的已公开专利列表（至少包括申请号/公开号、发明名称等信息）
            </label>
          </Col>
          <Col span={"9"} offset={1}>
            <label className="form-label">
              专利技术在产品中实施状况（自身产品或其它厂商产品，自主实施和/或由第三方机构实施情况，产生的市场价值、战略价值等）
            </label>
          </Col>
        </Row>

        <Row gutter={[16, 16]} style={{ marginTop: "15px" }}>
          <Col span={"9"}>
            <Form.Item name="patentList" noStyle>
              <Input.TextArea rows={4} />
            </Form.Item>
          </Col>

          <Col span={"9"} offset={1}>
            <Form.Item name="implementationStatus" noStyle>
              <Input.TextArea rows={4} />
            </Form.Item>
          </Col>
        </Row>

        {/* row 6 upload*/}
        <Form.Item
          name="upload"
          valuePropName="fileList"
          getValueFromEvent={normFile}
        >
          <Upload
            name="logo"
            maxCount={1}
            customRequest={handleUpload}
            beforeUpload={beforeUpload}
            fileList={fileList}
            defaultFileList={defaultFileList}
          >
            <Button className="uploadButton" type="default">
              <div>上传商业计划书 （可选）</div>
              <div style={{ color: "#999999" }}>文件不大于50M</div>
            </Button>
          </Upload>
        </Form.Item>

        {/*<button type="button" className="blueButton" onClick={submitForm}>
          提交报名信息
        </button>*/}
        <button type="button" className="blueButton" onClick={showTip}>
          提交报名信息
        </button>
      </Form>
    </div>
  );
};

export default Enterprise;
