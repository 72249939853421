import React, { useEffect, useState } from "react";

export interface DialogProps {
	onClose?: () => void;
}

const Dialog: React.FC<DialogProps> = (props) => {
	const { onClose } = props;
  const [count, setCount] = useState(0);
  useEffect(() => {
    document.body.style.overflow = "hidden";
    return () => {
      document.body.style.overflow = "inherit";
    };
  }, [count]);
  return (
    <div className="customDialog">
      <div onClick={() => onClose && onClose()} className="bg"></div>
      {props.children}
    </div>
  );
};

export default Dialog;
