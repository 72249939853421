/* eslint-disable */
/* tslint:disable */

import banner from "../../images/banner2.png";
import searchIcon from "../../images/icon/search.png";
import viewIcon from "../../images/icon/view.png";
import shareIcon from "../../images/icon/share.png";
import collIcon from "../../images/icon/coll.png";
import React, { useEffect, useState } from "react";
import { getEnv } from "../../helpers/mobx-easy-wrapper";
import { Radio, Rate, Pagination,Select } from "antd";
import { useLocation } from "react-router-dom";
import moment from "moment";
import Dialog from "../../components/dialog/dialog";

const newsList = [{}, {}, {}];

interface PatentItem {
  applyAddress: string;
  applyArea: string;
  applyCity: string;
  applyCityCode: string;
  applyDate: string;
  applyNum: string;
  applyer: string;
  createdAt: string;
  id: number;
  ipc: string;
  modifiedAt: string;
  openDate: string;
  openNum: string;
  patentType: string;
  summary: string;
  title: string;
  version: string;
  readTimes: Number;
  followerNumber: Number;
  shareTimes: Number;


  "thumbnail": string,
  "video": string,
  "labels": [],
  "content": string,
  "author": string,
  "publishAt": string,
  "hasExternalUrl": boolean,
  "externalUrl": string,
  "newsTypeIds": [
  ],
  "attachmentInfo": string,
  "sortNo": string
}

/**
 * applyAddress: "200433 上海市杨浦区邯郸路220号"
 applyArea: "[杨浦]"
 applyCity: "[上海]"
 applyCityCode: "上海; 31"
 applyDate: "2010-12-24"
 applyNum: "CN201010604436.3"
 applyer: "复旦大学"
 createdAt: "2022-03-27T01:05:19.406+0000"
 id: 512
 ipc: "G01D5/26; H04B10/08; H04B10/077"
 modifiedAt: null
 openDate: "2012-07-25"
 openNum: "CN102087356B"
 patentType: "发明授权"
 summary: "本发明属于分布式测量技术领域，具体为一种对两个具有固定时延差的叠加复合信号的处理方法。本发明具体涉及内部由两个具有延迟子信号叠加构成的一对复合信号所含信息的提取，这两个复合信号中子信号的延迟具有固定差值。该方法通过时域上的变换获得信号源的幅频特性，不仅可用于对扰动源特性的分析，也可以为信号频域的分析提供参考基准，消除信号源特性变化对信号处理的影响。本发明还可获得两个具有很强相关性的信号，采用时间延迟估计算法得到延迟信息，避免了将时域信号转化到频域内分析的复杂性，使数据处理更加方便。本发明特别适用于光纤分布式定位监测系统。"
 title: "对两个具有固定时延差的叠加复合信号的处理方法"
 version: null
 */
interface PatentResponse {
  items: Array<PatentItem>;
  totalItems: number;
  totalPages: number;
}

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const News = () => {
  const urlSearch = useQuery().get("search");
  const [pageNo, setPageNo] = useState(1);
  const [data, setData] = useState<Array<PatentItem>>();
  const [totalNums, setTotalNums] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const [dialogItem, setDialogItem] = useState<PatentItem>();
  const [showDialog, setShowDialog] = useState(false);

  const { apiFactory } = getEnv();
  const getData = () => {

    async function fetchData() {
      const response = await apiFactory.techService.getTechNews(
        pageNo
      );

      const totalPages = response.totalPages;


      setTotalNums(response.totalItems);
      setTotalPages(totalPages);
      setData(response.items);
    }
    fetchData();
  };

  const focusNews = async (newsId: string) => {
    const response = await apiFactory.techService.focusNews(newsId);
  }

  // empty array as second argument equivalent to componentDidMount
  useEffect(() => {
    getData();
  }, [pageNo]);
  /*  useEffect(() => {

      setSearch(inputSearch)
  }, [ inputSearch]);*/

  return (
    <div className="home">
      <div className="home-div-bannerContainer">
        <div>
          <img src={banner} />
        </div>
      </div>


      {/*区域*/}

      <div className="jury__line outlineTitle" style={{ marginLeft: "0px" }}>
        <label>新闻动态</label>
      </div>

      {showDialog && (
          <Dialog
              onClose={() => {
                setShowDialog(false);
              }}
          >
            <div className="fullDialog">
              <div
                  className="closeIcon"
                  onClick={() => {
                    setShowDialog(false);
                  }}
              ></div>
              <div className="dialogTitle">{dialogItem?.title}</div>
              <div className="dialogTime">
                {" "}
                {moment(dialogItem?.publishAt).format("YYYY年MM月DD日")}
              </div>
              <div
                  className="dialogContent"
                  dangerouslySetInnerHTML={{ __html: dialogItem!.content }}
              ></div>
              <div>
                <div className="dialogImg">
                  <img src={dialogItem?.thumbnail} />
                </div>
                <div className="buttonArea">
                  <button
                      className="primaryButton"
                      onClick={() => {

                      }}
                  >
                    追踪该发布人
                  </button>
                  <button
                      className="closeButton"
                      style={{marginLeft: "35px"}}
                      onClick={() => {
                        setShowDialog(false);
                      }}
                  >
                    关闭
                  </button>
                </div>
              </div>
            </div>
          </Dialog>
      )}

      <ul className="patent-div-container">
        {data?.map((item, index) => {
          return (
            <li
              key={index}
              className="patent-item"
              onClick={() => {
                setDialogItem(item);
                setShowDialog(true);
              }}
            >
         {/*     <div className="patent-div-header">
                <span className="metaInfo">
                  {item.title} / {item.publishAt}
                </span>
                <div className="rateContainer">
                  <Rate disabled defaultValue={5} />
                </div>
              </div>*/}
              <div className="patent-content">
                <div className="notice-div-content">
                  <div
                    className="notice-date"
                    onClick={() => {}
                    }
                  >
                    {item.title} / {moment(item.publishAt).format("YYYY-MM-DD")}

                    <div className="patentIcon">
                      <div>
                        <img src={viewIcon} />
                      </div>
                      <label>{item.followerNumber}</label>
                    </div>
                    <div className="patentIcon" onClick={(event)=>{ focusNews(item.id + ''); event.stopPropagation();event.preventDefault(); }}>
                      <div>
                        <img src={collIcon} />
                      </div>
                      <label>{item.followerNumber}</label>
                    </div>
                    <div className="patentIcon">
                      <div>
                        <img src={shareIcon} />
                      </div>
                      <label>{item.followerNumber}</label>
                    </div>

                  </div>
                  <div
                    className="notice-summary"
                    onClick={() =>
                    {}
                    }
                  >
                    {item.summary}
                  </div>
                </div>

                <div className="notice-div-img">
                  <img src={item.thumbnail} />
                </div>
              </div>
            </li>
          );
        })}
      </ul>

      <div className="pagination">
        {totalPages > 1 && (
          <Pagination
            current={pageNo}
            onChange={setPageNo}
            total={totalNums}
            showSizeChanger={false}
          />
        )}
        {/*   <div
          className="pagination__item"
          style={{ width: "72px" }}
          onClick={prevPage}
        >
          {" "}
          <div className="leftArrow"></div>上一页
        </div>
        {[...Array(totalPage)].map((item, i) => {
          return (
            <div
              key={i + "page"}
              className={`${
                pageNo == i + 1 ? "pagination__item active" : "pagination__item"
              }`}
              onClick={() => {
                setPageNo(i + 1);
              }}
            >
              {i + 1}
            </div>
          );
        })}
        <div
          className="pagination__item"
          style={{ width: "72px" }}
          onClick={nextPage}
        >
          下一页<div className="rightArrow"></div>
        </div>*/}
      </div>
    </div>
  );
};

export default News;
