/* eslint-disable */
/* tslint:disable */

import React from "react";
import { ConfigProvider, Layout } from "antd";
import { observer } from "mobx-react";
import MainRouter from "./features/routers/main-router";
import { useLocation } from "react-router-dom";
// order important so we can override antd
import "./App.scss";
import Header from "./components/header/header";
import Footer from "./components/footer/footer";

const { Content } = Layout;
/* 不需要header和footer的页面 */
const exclusionNavArray = ["/data", "/login","/yangpu"];
const exclusionHeaderArray = ["/login"];
const bgMap = {
  "/data": "bg__data",
  "/yangpu": "bg__data",
};

function App(props: any) {
  console.log(props.location);
  return (
    <ConfigProvider>
      <div
        className={`${
          exclusionNavArray.indexOf(window.location.pathname) < 0
            ? "has__header"
            : (bgMap as any)[window.location.pathname]
        }`}
      >
        <Layout className="App">
          {/*     {exclusionNavArray.indexOf(window.location.pathname) < 0 && (  )}*/}
          {exclusionHeaderArray.indexOf(window.location.pathname) < 0 && (
            <Header />
          )}

          <Content className="site-layout">
            <MainRouter />
          </Content>
        </Layout>
        {exclusionNavArray.indexOf(window.location.pathname) < 0 && <Footer />}
      </div>
    </ConfigProvider>
  );
}

export default observer(App);
