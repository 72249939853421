/* eslint-disable */
/* tslint:disable */

import banner from "../../images/banner2.png";
import searchIcon from "../../images/icon/search.png";
import viewIcon from "../../images/icon/view.png";
import shareIcon from "../../images/icon/share.png";
import collIcon from "../../images/icon/coll.png";
import React, { useEffect, useState, Fragment } from "react";
import { getEnv } from "../../helpers/mobx-easy-wrapper";
import { Radio, Rate, Pagination } from "antd";
import {
  useLocation
} from "react-router-dom";

const newsList = [{}, {}, {}];

interface PatentItem {
  applyAddress: string;
  applyArea: string;
  applyCity: string;
  applyCityCode: string;
  applyDate: string;
  applyNum: string;
  applyer: string;
  createdAt: string;
  id: number;
  ipc: string;
  modifiedAt: string;
  openDate: string;
  openNum: string;
  patentType: string;
  summary: string;
  title: string;
  version: string;
}

/**
 * applyAddress: "200433 上海市杨浦区邯郸路220号"
 applyArea: "[杨浦]"
 applyCity: "[上海]"
 applyCityCode: "上海; 31"
 applyDate: "2010-12-24"
 applyNum: "CN201010604436.3"
 applyer: "复旦大学"
 createdAt: "2022-03-27T01:05:19.406+0000"
 id: 512
 ipc: "G01D5/26; H04B10/08; H04B10/077"
 modifiedAt: null
 openDate: "2012-07-25"
 openNum: "CN102087356B"
 patentType: "发明授权"
 summary: "本发明属于分布式测量技术领域，具体为一种对两个具有固定时延差的叠加复合信号的处理方法。本发明具体涉及内部由两个具有延迟子信号叠加构成的一对复合信号所含信息的提取，这两个复合信号中子信号的延迟具有固定差值。该方法通过时域上的变换获得信号源的幅频特性，不仅可用于对扰动源特性的分析，也可以为信号频域的分析提供参考基准，消除信号源特性变化对信号处理的影响。本发明还可获得两个具有很强相关性的信号，采用时间延迟估计算法得到延迟信息，避免了将时域信号转化到频域内分析的复杂性，使数据处理更加方便。本发明特别适用于光纤分布式定位监测系统。"
 title: "对两个具有固定时延差的叠加复合信号的处理方法"
 version: null
 */
interface PatentResponse {
  items: Array<PatentItem>;
  totalItems: number;
  totalPages: number;
}

const LevelSvg = function () {
  return (
    <svg
      style={{ marginRight: "10px" }}
      width="11.442"
      height="12.137"
      viewBox="0 0 11.442 12.137"
    >
      <g id="组_31" data-name="组 31" transform="translate(-1014.616 -988.751)">
        <path
          id="路径_84"
          data-name="路径 84"
          d="M1020.337,989.861l4.721,2.274V999.3l-4.287-2.064-.434-.209-.434.209-4.287,2.064v-7.161l4.721-2.274m0-1.11-5.721,2.755v9.382l5.721-2.755,5.721,2.755v-9.382l-5.721-2.755Z"
          fill="#1e43ad"
        />
      </g>
    </svg>
  );
};

const FireSvg = function () {
  return (
    <svg
      style={{ marginRight: "10px" }}
      width="11.052"
      height="14.004"
      viewBox="0 0 11.052 14.004"
    >
      <path
        id="路径_71"
        data-name="路径 71"
        d="M1520.545,1001.194c-3.376,0-5.39-2.092-5.39-5.6a5.939,5.939,0,0,1,2.087-4.743,4.173,4.173,0,0,0,1.594-3.259.41.41,0,0,1,.164-.332.356.356,0,0,1,.354-.043c1.736.746,3.235,3.461,3.32,5.214.423-.354.551-1.372.656-2.2l.023-.181a.4.4,0,0,1,.253-.328.354.354,0,0,1,.38.1,8.912,8.912,0,0,1,2.221,5.581,5.423,5.423,0,0,1-5.662,5.782Zm-1.028-12.892a4.823,4.823,0,0,1-1.712,3.171,4.977,4.977,0,0,0-1.813,4.084c0,4.241,3.187,4.7,4.558,4.7a4.561,4.561,0,0,0,4.817-4.887,6.569,6.569,0,0,0-1.331-4.1,3.477,3.477,0,0,1-1.044,2.068.6.6,0,0,1-.651.092.683.683,0,0,1-.379-.6,7,7,0,0,0-2.445-4.523Z"
        transform="translate(-1515.155 -987.19)"
        fill="#1e43ad"
      />
    </svg>
  );
};

const StarSvg = function () {
  return (
    <svg
      style={{ marginRight: "10px" }}
      width="14.74"
      height="14.715"
      viewBox="0 0 14.74 14.715"
    >
      <path
        id="路径_80"
        data-name="路径 80"
        d="M565.168,996.224l.871,5.326-4.563-2.516-4.563,2.516.871-5.326-3.678-3.759,5.085-.775,2.285-4.855,2.286,4.855,5.084.775ZM563.19,992.5l-1.714-3.681-1.73,3.681-3.985.517,2.946,3-.653,3.864,3.422-1.914,3.379,1.929-.61-3.865,2.917-2.971Z"
        transform="translate(-554.106 -986.835)"
        fill="#1e43ad"
      />
    </svg>
  );
};

interface PatentItem {
  applyAddress: string;
  applyArea: string;
  applyCity: string;
  applyCityCode: string;
  applyDate: string;
  applyNum: string;
  applyer: string;
  createdAt: string;
  id: number;
  ipc: string;
  modifiedAt: string;
  openDate: string;
  openNum: string;
  patentType: string;
  summary: string;
  title: string;
  version: string;

  FOLLOWER_NUMBER: number;
  PATENT_ABSTRACT: string;
  PATENT_APPLICANT: string;
  PATENT_APPLICATION_NUMBER: string;
  PATENT_BULLETIN_NUMBER: string;
  PATENT_COMPREHENSIVE: number;
  PATENT_FEVER: string;
  PATENT_ID: string;
  PATENT_IID: string;
  PATENT_INVENTOR: string;
  PATENT_IPC: string;
  PATENT_LEGAL_CURRENT_STATUS: string;
  PATENT_SCORE: string;
  PATENT_TID: string;
  PATENT_TITLE: string;
  PATENT_VALIDITY: string;
  READ_TIMES: number;
  SHARE_TIMES: number;
  PATENT_APPLICATION_DAY: string;
}
interface PatentSugges {
  name: string;
  content: string;
}

const PatentDetail = () => {
  const [data, setData] = useState<PatentItem>();
  const [sugges, setSugges] = useState<Array<PatentSugges>>();
  const { search } = useLocation();
  const { apiFactory } = getEnv();

  const getData = () => {
    const uid = new URLSearchParams(search).get('uid')!;
    const pid = new URLSearchParams(search).get('pid')!;
    console.log('search', search);
    const id = window.location.hash.replace("#", "");
    const user = apiFactory.authService.getLoginUser();
    async function fetchData() {
      const response = await apiFactory.techService.getTechDetailData(uid, pid, user);
      console.log("response", response);
      setData(response.pat_data[0]);
      setSugges(response.sugges);
    }
    fetchData();
  };
  /*

  applyAddress: "242300 安徽省宁国市港口生态工业园区经三路南侧"
applyArea: "[宁国]"
applyCity: "[宣城]"
applyCityCode: "安徽; 34"
applyDate: "2012-07-02"
applyNum: "CN201210223219.9"
applyer: "宁国市龙晟柔性储能材料科技有限公司"
createdAt: "2022-03-27T01:13:42.131+0000"
followerNumber: 0
id: 1377
ipc: "H01L51/48; H01G9/042; H01G9/20"
modifiedAt: "2022-04-19T15:33:38.163+0000"
openDate: "2015-10-28"
openNum: "CN102737853B"
patentType: "发明授权"
readTimes: 3
shareTimes: 0
summary: "本发明属于太阳能电池技术领域，具体为一种高性能取向碳纳米管薄膜电极的制备方法。本发明方法包括：采用胶带法制备取向碳纳米管薄层阵列，采用加压法制备高性能取向碳纳米管薄膜电极。该方法操作简单，可控性好，制备的碳纳米管薄膜电极表面平整且均一。将该取向碳纳米管薄膜电极用于太阳能电池的对电极，所组装的太阳能电池具有优异的性能，其效率可以超过相同条件下采用传统铂作为对电极的效率。"
title: "一种高性能取向碳纳米管薄膜电极的制备方法"
version: 3
  * */

  // empty array as second argument equivalent to componentDidMount
  useEffect(() => {
    getData();
  }, []);
  return (
    <div className="home">
      <div className="home-div-bannerContainer">
        <div>
          <img src={banner} />
        </div>
      </div>

      {/*
      <div className="jury__line outlineTitle" style={{ marginLeft: "0px" }}>
        <label>新闻动态</label>
      </div>*/}

      <div className="patent-div-container">
        <div className="patentDetail-title">{data?.PATENT_TITLE}</div>
        <div className="patentDetail-detail">{data?.PATENT_ABSTRACT}</div>
        <table className="patentDetail-applyTable">
          <tr>
            <td>申请号：</td>
            <td>{data?.PATENT_APPLICATION_NUMBER}</td>
          </tr>
          <tr>
            <td>申请日：</td>
            <td>{data?.PATENT_APPLICATION_DAY}</td>
          </tr>
          <tr>
            <td>申请人：</td>
            <td>{data?.PATENT_APPLICANT}</td>
          </tr>
          <tr>
            <td>当前法律状态：</td>
            <td>{data?.PATENT_LEGAL_CURRENT_STATUS}</td>
          </tr>
          <tr>
            <td>发明人：</td>
            <td>{data?.PATENT_INVENTOR}</td>
          </tr>
          <tr>
            <td>IPC：</td>
            <td>{data?.PATENT_IPC}</td>
          </tr>
        </table>

        <div className="patentDetail-label">专利评分</div>
        <table className="patentDetail-rateTable">
          <tr>
            <td>
              综合评分&nbsp;&nbsp;
              {Array.from(Array(Number.parseInt(String(data?.PATENT_COMPREHENSIVE ? data?.PATENT_COMPREHENSIVE : 0))), (e, i) => {
                return <StarSvg key={i} />
              })}
            </td>
            <td>
              专利评价&nbsp;&nbsp;
              {Array.from(Array(Number.parseInt(String(data?.PATENT_SCORE ? data?.PATENT_SCORE : 0))), (e, i) => {
                return <LevelSvg key={i} />
              })}
            </td>
            <td>
              市场热度&nbsp;&nbsp;
              {Array.from(Array(Number.parseInt(String(data?.PATENT_FEVER ? data?.PATENT_FEVER : 0))), (e, i) => {
                return <FireSvg key={i} />
              })}
            </td>
          </tr>
        </table>
        <div className="patentDetail-label">交易方式建议</div>
        <table className="patentDetail-advTable">
          {
            sugges?.map((item,index) => {
              return (
                  <>
                      <section>
                        {item.name}<p></p>
                        <div className="solidButton">
                          {item.content}
                        </div>
                      </section>
                  </>
              )
            })
          }
       {/*   <tr>
            <td>
              技术能力强， 市场能力弱<p></p>
              <div className="solidButton">许可</div>
            </td>
            <td>
              技术能力强， 市场能力强弱<p></p>
              <div className="solidButton">
                许可、作价入股、 成立合资公司，对外许可
              </div>
            </td>
          </tr>
          <tr>
            <td>
              技术能力弱， 市场能力强弱<p></p>
              <div className="solidButton">许可</div>
            </td>
            <td>
              技术能力弱， 市场能力强弱<p></p>
              <div className="solidButton">
                许可、作价入股、 成立合资公司，对外许可
              </div>
            </td>
          </tr>*/}
        </table>
        <div className="patenDetail-file">
          <div className="contact">
            <div className="fileButton">联系专利人</div>
            <br></br>李刚，产学研合作部，021-64252804
          </div>
          <div className="fileButton fileButton-disabled">专利市场交易分析报告下载</div>
          <div className="fileButton fileButton-disabled">专利市场交易分析报告下载</div>
        </div>
      </div>
    </div>
  );
};

export default PatentDetail;
