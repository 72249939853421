import React, { useEffect, useState } from "react";
import { getEnv } from "../../../helpers/mobx-easy-wrapper";
import moment from "moment";
import Dialog from "../../../components/dialog/dialog";

interface NewsItem {
  id: string;
  title: string;
  author: string;
  content: string;
  creator: string;
  publishAt: string;
  summary: string;
  thumbnail: string;
  video: string;
  hasExternalUrl: Boolean;
  externalUrl: string;
}

const NewsBlock: React.FC  = () => {
	const [patentNewsList, setPatentNewsList] = useState([]);
	const [showDialog, setShowDialog] = useState(false);
	const [dialogItem, setDialogItem] = useState<NewsItem>();
	
	let pageNo = 1;
	useEffect(() => {
	  async function fetchData() {
	    const { apiFactory } = getEnv();
	    const response = await apiFactory.patentService.getPatentnewsData(pageNo);
	    if (response.totalPages === pageNo) {
	      pageNo = 0;
	    }
	    setPatentNewsList((prevData) => {
	      return prevData.concat(response.items);
	    });
	  }
	  fetchData();
	}, []);
	
    return (
        <div className="news-div-container dashDashboard-block">
            <ul>
				{patentNewsList.map((item: any) => {
				  return (
					<li onClick={()=> {
						const hasExternalUrl = item.hasExternalUrl;
						if (hasExternalUrl) {
							window.open(item.externalUrl, '_blank');
						} else {
							setDialogItem(item)
							setShowDialog(true);
						}
					}}>
						<div className="news-div-title">{item.title}</div>
						<span className="news-div-time">{moment(item.publishAt).format('HH:mm')}</span>
					</li>
                );
                })}
            </ul>
			{showDialog && (
			  <Dialog onClose={() => {
						setShowDialog(false);
					}}>
			    <div className="fullDialog">
			      <div
			        className="closeIcon"
			        onClick={() => {
			          setShowDialog(false);
			        }}
			      ></div>
			      <div className="dialogTitle">{dialogItem?.title}</div>
			      <div className="dialogTime">
			        {" "}
			        {moment(dialogItem?.publishAt).format("YYYY年MM月DD日")}
			      </div>
			      <div
			        className="dialogContent"
			        dangerouslySetInnerHTML={{ __html: dialogItem!.content }}
			      ></div>
			      <div>
			        <div className="dialogImg">
			          <img src={dialogItem?.thumbnail} />
			        </div>
			        <div className="buttonArea">
			          <button
			            className="closeButton"
			            onClick={() => {
			              setShowDialog(false);
			            }}
			          >
			            关闭
			          </button>
			        </div>
			      </div>
			    </div>
			  </Dialog>
			)}
        </div>
    );
};

export default NewsBlock;
