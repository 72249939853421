/* eslint-disable */
/* tslint:disable */

import React, { useEffect, useLayoutEffect, useRef, useState } from "react";

import Banner from "./banner";
import ActivityIntro from "./intro/intro";
import ActivityOrg from "./organization/org";
import ActivityRouter from "../routers/activity-router";
import { count } from "echarts/types/src/component/dataZoom/history";

const Activity = () => {
  const menuDivRef = useRef<HTMLDivElement>(null);
  useLayoutEffect(() => {
    const middle = menuDivRef.current as any;

    setTimeout(() => {
      const top = middle.getBoundingClientRect().top;
      const height = middle.getBoundingClientRect().height;
      window.onscroll = function (ev) {
        const bottomHeight =
          document.body.scrollHeight -
          (document.documentElement.scrollTop + height);
        if (document.documentElement.scrollTop > top) {
          if (bottomHeight <= 260) {
            middle.style.position = "absolute";
            middle.style.bottom = "0px";
            middle.style.top = "unset";
          } else {
            middle.style.position = "fixed";
            middle.style.top = "0px";
          }
        } else {
          middle.style.position = "absolute";
        }
      };
    }, 300);
  });
  return (
    <div className="home" style={{ backgroundColor: "#FFF" }}>
      <Banner />
      <div className="main-div-container">
        <div ref={menuDivRef} className="leftNav-div-container">
          <ul>
            <li onClick={() => (location.href = "/match")} className="active">
              <span>大赛背景</span>
            </li>
            <li onClick={() => (location.href = "/match#subject")}>
              大赛主题和定位
            </li>
            <li onClick={() => (location.href = "/match#org")}>组织架构</li>
            <li onClick={() => (location.href = "/match#condition")}>
              参赛要求
            </li>
            <li onClick={() => (location.href = "/match#schedule")}>
              大赛赛程
            </li>
            <li onClick={() => (location.href = "/match#prize")}>大赛奖项</li>
            <li>
              <div className="hr"></div>
            </li>
            <li onClick={() => (location.href = "/match/jury")}>大赛评委</li>
            <li>
              <div className="hr"></div>
            </li>
            <li onClick={() => (location.href = "/match/notice")}>大赛动态</li>
            <li>
              <button
                type="button"
                onClick={() => (location.href = "/match/enterprise")}
              >
                企业报名入口
              </button>
            </li>
            <li>
              <button
                type="button"
                onClick={() => (location.href = "/match/college")}
              >
                高校院所报名入口
              </button>
            </li>
          </ul>
        </div>
        <div className="content-div-container">
          <ActivityRouter />
        </div>
      </div>

      {/*    <ActivityIntro />
      <ActivityOrg />*/}
    </div>
  );
};

export default Activity;
