import HttpService from "./http-service";
import AuthService from "../api/auth-service";
import TodosService from "../api/todos-service";
import TechService from "../api/tech-service";
import ActivityService from "../api/activity-service";
import PatentnewsService from "../api/patentnews-service";
import DataService from "../api/data-service";


const ApiList = [
	{
	  variableName: "patentService",
	  classEntity: PatentnewsService,
	  route: PatentnewsService.route,
	},
  {
    variableName: "todosService",
    classEntity: TodosService,
    route: TodosService.route,
  },
  {
    variableName: "authService",
    classEntity: AuthService,
    route: AuthService.route,
  },
  {
    variableName: "techService",
    classEntity: TechService,
    route: TechService.route,
  },
  {
    variableName: "activityService",
    classEntity: ActivityService,
    route: ActivityService.route,
  },
  {
    variableName: "dataService",
    classEntity: DataService,
    route: DataService.route,
  },
];

export interface ApiFactoryParams {
  httpService: HttpService;
}

// declaration merging with class
interface ApiFactory {
  todosService: TodosService;
  authService: AuthService;
  techService: TechService;
  activityService: ActivityService;
  dataService: DataService;
  patentService: PatentnewsService;
}

class ApiFactory {
  private httpService: HttpService;

  [index: string]: any;

  constructor({ httpService }: ApiFactoryParams) {
    this.httpService = httpService;

    ApiList.forEach((api) => {
      this[api.variableName] = new api.classEntity({
        httpService,
        route: api.route,
      });
    });
  }

  saveToken(token: string) {
    this.httpService.setToken(token);
  }
}

export default ApiFactory;
