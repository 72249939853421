/* eslint-disable */
/* tslint:disable */

import React from "react";
import banner from "../../images/banner.png";

import Banner from "./banner";
import Info from "./info";
import Service from "./service";

const Home = () => {
  return (
    <div className="home">
      <Banner />
      <Info />
      <Service />
      <img className="home-div-activityContainer" src={banner} />
    </div>
  );
};

export default Home;
