/* eslint-disable */
/* tslint:disable */

import React, { useState } from "react";
import HexagonBlock from "./hexagon";
import { useLocation } from "react-router-dom";

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}
export interface HexagenBarProps {
  areaList: Array<{ area: string; progress: string; value: number }>;
}
const HexagenBar: React.FC<HexagenBarProps> = (props) => {
  const areaName = useQuery().get("name");

  /*  const areaList = [
    { area: "普陀", progress: "50%", value: 20000 },
    { area: "闵行", progress: "60%", value: 20000 },
    { area: "宝山", progress: "40%", value: 20000 },
    { area: "静安", progress: "15%", value: 20000 },
    { area: "黄浦", progress: "70%", value: 20000 },
    { area: "嘉定", progress: "50%", value: 20000 },
    { area: "奉贤", progress: "20%", value: 20000 },
    { area: "青浦", progress: "10%", value: 20000 },
    { area: "金山", progress: "56%", value: 20000 },
    { area: "松江", progress: "27%", value: 20000 },
    { area: "浦东", progress: "35%", value: 20000 },
    { area: "杨浦", progress: "15%", value: 20000 },
    { area: "崇明", progress: "15%", value: 20000 },
    { area: "虹口", progress: "15%", value: 20000 },
    { area: "长宁", progress: "15%", value: 20000 },
    { area: "徐汇", progress: "15%", value: 20000 },
  ];*/
  const [active, setActive] = useState("");
  const setActiveArea = (areaName: string) => {
    active === areaName ? setActive("") : setActive(areaName);
  };
  return (
    <div className="map-div-container dashDashboard-block">
      <div className="map-div-svgContainer">
        <HexagonBlock />
        <div className="map-div-barChart">
          {props.areaList.map((item) => {
            return (
              <div
                className={
                  item.area === areaName
                    ? "barChart-div-bar active"
                    : "barChart-div-bar"
                }
                style={{ height: item.progress }}
              >
                <div className="bar-div-data">{item.value}</div>
                <div className="bar-div-title">{item.area}</div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default HexagenBar;
