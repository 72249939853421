/* eslint-disable */
/* tslint:disable */

import React from "react";
import { ReactECharts, ReactEChartsProps } from "../../echarts/echarts";

function getChartOption(param: BarChartProps) {
  const option = {
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "shadow",
      },
    },
    xAxis: {
      type: "category",
      data: param.xAxis,
      nameTextStyle: {
        fontSize: 10,
      },
    },
    yAxis: {
      show: false,
      type: "value",
    },
    series: [
      {
        data: param.value,
        type: "bar",
        showBackground: true,
        backgroundStyle: {
          color: "rgba(180, 180, 180, 0.2)",
        },
        itemStyle: {
          normal: {
            label: { show: true, top: "top" },
          },
        },
      },
    ],
  };
  return option;
}

const style = {
  height: "290px",
  marginLeft: "-10px",
  width: "calc(100% + 25px)",
};

export interface BarChartProps {
  title: string;
  xAxis?: string[];
  value?: number[];
}

const BarChartBlock: React.FC<BarChartProps> = (props) => {
  console.log(getChartOption(props));
  return (
    <div className="barChart-div-block dashDashboard-block">
      <div className="barChart-div-title">{props.title}</div>
      <ReactECharts option={getChartOption(props) as any} style={style} />
    </div>
  );
};

export default BarChartBlock;
