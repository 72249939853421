import axios, { AxiosInstance } from "axios";

export interface HttpServiceConfig {
  baseURL?: string;
}

export default class HttpService {
  private token: string = "";
  private guest: string = "";
  private axiosInstance: AxiosInstance;
  constructor({ baseURL }: HttpServiceConfig) {
    this.token = this.getToken();
    this.guest = this.getGuest();

    this.axiosInstance = axios.create({
      baseURL,
      headers: {
        "Content-Type": "application/json;charset=UTF-8",
        "X-Auth-Token": this.token || this.guest,
      },
    });
    this.initAxiosConfig();
  }

  initAxiosConfig() {
    this.axiosInstance.interceptors.response.use(
      (response) => {
        if (
          response.status === 200 &&
          response.request.url &&
          response.request.url.includes("/api/login")
        ) {
          const xToken = response.headers["x-auth-token"];
          if (xToken) {
            // this.token = xToken;
            this.setToken(xToken);
          }
        } else if (
          !response.headers.url &&
          response.request.data &&
          response.request.data.hasOwnProperty("credentials")
        ) {
          // 处理在ie下reponse.url = ''无法进入设置token的问题
          const xToken = response.headers["x-auth-token"];
          if (xToken) {
            // this.token = xToken;
            this.setToken(xToken);
          }
        } else {
          const xToken = response?.headers["x-auth-token"];
          if (xToken) {
            // this.guest = xToken
            this.setGuest(xToken);
          }
        }

        return response;
      },
      function (error) {
        console.log('error', error.response);
        try{
          if (error.response.status == 401) {
            return error.response;
          }
        } catch (e) {

        }
		
        // Any status codes that falls outside the range of 2xx cause this function to trigger
        // Do something with response error
        return Promise.reject(error.response);
      }
    );
  }

  async upload<T, R>(url: string, data?: T) {
    console.log("data", data);
    const response = await this.axiosInstance.post(url, data, {
      headers: {
        "Content-Type": "multipart/form-data",
        "X-Auth-Token": this.token || this.guest,
      },
    });

    return response;
  }

  async get<T>(url: string): Promise<T> {
    const response = await this.axiosInstance.get(url);

    return response.data as T;
  }

  async postResponse<T>(url: string, data?: T) {
    const response = await this.axiosInstance.post(url, data);

    return response;
  }

  async post<T, R>(url: string, data?: T) {
    const response = await this.axiosInstance.post(url, data);

    return response.data as R;
  }

  async put<T, R>(url: string, data: T) {
    const response = await this.axiosInstance.put(url, data);

    return response.data as R;
  }

  async delete<R>(url: string) {
    const response = await this.axiosInstance.delete(url);

    return response.data as R;
  }

  getToken() {
    const t = localStorage.getItem("token");
    return t ? t : "";
  }

  getGuest() {
    const g = localStorage.getItem("guest");
    return g ? g : "";
  }

  setToken(token: string) {
    this.token = token;
    console.log("缓存token", token);
    localStorage.setItem("token", token);
    // this.axiosInstance.defaults.headers.Authorization = `Bearer ${this.token}`;
  }
  setGuest(token: string) {
    this.guest = token;
    localStorage.setItem("guest", token);
    // this.axiosInstance.defaults.headers.Authorization = `Bearer ${this.token}`;
  }
}
