/* eslint-disable */
/* tslint:disable */

import React from "react";
import glod from "../../images/glod.png";
import silver from "../../images/silver.png";
import copper from "../../images/copper.png";

interface StepProps {
  month: string;
  title: string;
  content: string;
}

const ActivityStep = (props: StepProps) => {
  return (
    <div className="step-div-container">
      <div className="stepTitle">{props.month}</div>
      <div className="stepSign-div-container">
        <div className="leftLine"></div>
        <div className="stepPoint"></div>
        <div className="rightLine"></div>
      </div>
      <p style={{ paddingLeft: "8px", paddingRight: "8px" }}>
        <div className="txt-center">{props.title}</div>
        {props.content}
      </p>
    </div>
  );
};

const ActivityDetail = () => {
  return (
    <div className="home activityDetail-div-container">
      <div className="outlineTitle">
        <label>大赛背景</label>
      </div>
      <p>
        专利是科技成果转化的源泉，产业的创新化之路离不开专利源头的涓涓长流。为促进知识产权高质量创造，推动知识产权高效运用，在上海知识产权局的指导下，上海技术交易所特举办上海市高价值专利大赛（以下简称“大赛”），挖掘和培育一批创新程度高、市场竞争力强、专利优势明显的创新项目，培育知识产权文化，引导全社会对专利工作的关注和投入，推动上海高校院所、企业的专利转移转化工作,充分释放和大力彰显专利资源的创新价值、经济效益和社会效应,为上海经济社会高质量发展注入新动能、塑造新优势。
      </p>
      <div className="outlineTitle" id="subject">
        <label>大赛背景</label>
      </div>
      <div className="subTitle-zh">技术驱动未来</div>
      <div className="subTitle-en">TECHNOLOGY INSPIRES THE FUTURE</div>
      <p>
        充分挖掘上海市内拥有显著技术创新性、先进性的高价值专利，不强求专利拥有者对专利布局的已有能力，而是着重通过大赛在挖掘的基础上通过配套的培育服务、比赛锤炼等方式提升专利拥有者对专利布局的意识和能力，进而促进专利技术更好地进行产业应用。
      </p>

      <div className="outlineTitle" id="org">
        <label>组织架构</label>
      </div>
      <div className="subTitle-zh">主办单位</div>
      <div className="orgUnit-div-container">上海市知识产权局</div>
      <div className="subTitle-zh">承办单位</div>
      <div className="orgUnit-div-container">上海技术交易所</div>
	  <div className="orgUnit-div-container">上海市高校科技发展中心</div>
      <div className="subTitle-zh">
        支持单位<label>(排名不分先后)</label>
      </div>
      <div className="orgUnit-div-container">集聚区</div>
      <div className="orgUnit-div-container">知识产权机构</div>
      <div className="orgUnit-div-container">金融机构</div>
      <div className="orgUnit-div-container">投资机构</div>
      <div className="orgUnit-div-container">高校院所</div>
      <div className="orgUnit-div-container">企业</div>
      <div className="orgUnit-div-container">技转机构</div>
      <div className="outlineTitle" id="condition">
        <label>参赛要求</label>
      </div>
      <p>
        1.
        本次大赛分成高校院所组、企业组两个组别，上海市范围内的创新主体（高校、科研院所、医疗卫生机构、企业、科创团队、个人等）均可参赛。
        2.
        如以单个专利或专利组合参赛的，应为2022年3月15日前授权的有效专利，不得有知识产权纠纷；如以具有专利优势的科技创新项目参赛的，需包含能够有效保护参赛产品创新成果的专利组合及至少一项核心专利，且不得有知识产权纠纷，近三年（2019年1月1日之后）新推向市场或准面市的项目优先。
        3.
        所有科技领域涉及的专利、科技创新项目皆可参赛，属于上海“9+X”战略性新兴产业和先导产业的优先。
      </p>

      <div className="outlineTitle" id="schedule">
        <label>大赛赛程</label>
      </div>
      <div className="progressStep-div-container">
        <ActivityStep
          month="4-5月"
          title="报名征集"
          content="符合条件的参赛主体通过本页面左侧报名入口，根据实际情况选择“高校院所组”或“企业组”通道，填写大赛参赛申请表进行报名。报名截止日期：2022年5月20日。"
        />
        <ActivityStep
          month="5月下旬"
          title="初筛"
          content="大赛组委会对所有报名项目进行初筛，并对外发布“入围100”名单。"
        />
        <ActivityStep
          month="4-5月"
          title="评审初选"
          content="大赛组委会将符合参赛要求的入围100项目交由专家委员会进行初评，遴选出30个优秀项目进入决赛，并对外发布“入围30强”短名单。评审考察要点：• 高校院所组：技术创新性、先进性、市场应用前景展望等；• 企业组：技术创新性、先进性、市场应用前景、市场规模、市场竞争力等。"
        />
        <ActivityStep
          month="4-5月"
          title="报名征集"
          content="符合条件的参赛主体通过本页面左侧报名入口，根据实际情况选择“高校院所组”或“企业组”通道，填写大赛参赛申请表进行报名。报名截止日期：2022年5月20日。"
        />
      </div>

      <div className="outlineTitle" id="prize">
        <label>大赛奖项</label>
      </div>
      <div style={{ marginTop: "25px" }}>
        <div className="awardItem-div-container">
          <div className="awardImg-container">
            <img src={glod} />
          </div>
          <div className="awardDesc-container">
            <div className="awardName">金奖:</div>
            <div className="awardCount">每组别各1名</div>
            <div className="awardAmount">5万元现金 + 增值奖励</div>
          </div>
        </div>
        <div className="awardItem-div-container">
          <div className="awardImg-container">
            <img src={silver} />
          </div>
          <div className="awardDesc-container">
            <div className="awardName">银奖:</div>
            <div className="awardCount">每组别各2名</div>
            <div className="awardAmount">2万元现金 + 增值奖励</div>
          </div>
        </div>
        <div className="awardItem-div-container">
          <div className="awardImg-container">
            <img src={copper} />
          </div>
          <div className="awardDesc-container">
            <div className="awardName">铜奖：</div>
            <div className="awardCount">每组别各3名</div>
            <div className="awardAmount">1万元现金 + 增值奖励</div>
          </div>
        </div>
      </div>
      <p>增值奖励：</p>
      <p>（1） 根据项目情况优先享受上海技术交易所挂牌交易“直通车”服务；</p>
      <p>（2） 获得由多家投资机构和基金提供的投资对接服务；</p>
      <p>（3） 获得由多家金融机构提供的专利质押贷款直通车服务；</p>
      <p>（4） 获得《上技会客厅》栏目专访机会，及“上技路演”项目推广服务；</p>
      <p>（5） 优先通过大赛官网和多种渠道进行推介宣传。</p>
      <p>（6）更多惊喜不断加入！</p>
    </div>
  );
};

export default ActivityDetail;
