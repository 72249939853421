/* eslint-disable */
/* tslint:disable */

import React, {useEffect, useState} from "react";
import { useLocation } from "react-router-dom";
import DashboardTitle from "./title/title";
import MeterBlock from "../../components/charts/meter/meter-block";
import StackAreaBlock from "../../components/charts/stack-area/stack-area-block";
import Card from "../../components/charts/card/card";
import MapChart from "../../components/charts/map/map";
import NewsBlock from "./news/news";
import WeatherBlock from "./weather/weather";
import HexagenBar from "../../components/charts/hexagon/hexagenBar";
import BarChartBlock from "../../components/charts/bar/bar";
import cardCountIcon from "../../images/icon/card-count.png";
import cardInIcon from "../../images/icon/card-in.png";
import cardOutIcon from "../../images/icon/card-out.png";
import cardUpIcon from "../../images/icon/card-up.png";

import shanghaiData from "../../data/shanghai.json";
import { formatNum } from "../../utils/NumberUtil";
import {getEnv} from "../../helpers/mobx-easy-wrapper";
import {IData, IHandleData} from "./idata";

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}


function handleData(areaData: any) {
  //专利评级分布
  const pingjiMap = {
    xAxis: [],
    value: [],
  };
  //专利价格分布
  const jiageMap = {
    xAxis: [],
    value: [],
  };

  const shiYongCount = Number.parseInt(areaData.patentType.shiYong);
  const faMingCount = Number.parseInt(areaData.patentType.faMing);
  // 实用专利百分比
  const fmPercent = (faMingCount / (shiYongCount + faMingCount)).toFixed(2);
  const syzlMap = {
    percent: fmPercent,
    totalCount: shiYongCount + faMingCount,
  };

  //申请企业数量初次
  // areaData.addQiYeNumsFa
  const firstQYTotalCount = areaData.addQiYeNumsFa.qiYeNums.split(",");
  const firstQYCountsMap = {
    xAixs: areaData.addQiYeNumsFa.years.split(","),
    legend: ["总数", "初次申请专利"],
    num: formatNum(firstQYTotalCount[firstQYTotalCount.length - 2]),
    series: [
      {
        key: "总数",
        totalFlag: true,
        data: areaData.addQiYeNumsFa.qiYeNums.split(","),
      },
      {
        key: "初次申请专利",
        totalFlag: false,
        data: areaData.addQiYeNumsFa.addQiYeNums.split(","),
      },
    ],
  };

  const totalCountArray: any[] = [];
  console.log('areaData.patentQuShi', areaData.patentQuShi)
  areaData.patentQuShi.map((item: any) => {
    totalCountArray.push(Number.parseInt(item.num));
  });

  //涨幅
  const year2021 = Number.parseInt(
    areaData.patentQuShi[areaData.patentQuShi.length - 2].num
  );
  const year2020 = Number.parseInt(
    areaData.patentQuShi[areaData.patentQuShi.length - 3].num
  );
  const insPer = (((year2021 - year2020) / year2020) * 100).toFixed(0) + "%";

  const inArray = areaData.tecTrade.in.split(",");
  const outArray = areaData.tecTrade.out.split(",");
  // 流入 流出
  const inCount = inArray[inArray.length - 2];
  const outCount = outArray[outArray.length - 2];

  //仅仅发明 企业占比
  const addQiYeNums = areaData.addQiYeNumsFaMing.addQiYeNums.split(",");
  const qyNums = areaData.addQiYeNumsFaMing.qiYeNums.split(",");

  const jfm = Number.parseInt(
    (Number.parseInt(addQiYeNums[addQiYeNums.length - 2]) /
      Number.parseInt(qyNums[qyNums.length - 2])) *
      100 +
      ""
  );

  const jfmMap = {
    percent: jfm,
    count: qyNums[qyNums.length - 2],
  };

  const zhuanLiCountMap = {
    xAixs: areaData.patentShenQingQuShi.years.split(","),
    legend: ["总数", "高端装备制造", "新一代信息技术", "新材料"],
    num: formatNum(totalCountArray[totalCountArray.length - 2]),
    series: [
      { key: "总数", totalFlag: true, data: totalCountArray },
      {
        key: "高端装备制造",
        totalFlag: false,
        data: areaData.patentShenQingQuShi.gaoDuanZhiZhao.split(","),
      },
      {
        key: "新一代信息技术",
        totalFlag: false,
        data: areaData.patentShenQingQuShi.xinXiJiShu.split(","),
      },
    ],
  };
  // 区级没有新材料
  if (areaData.patentShenQingQuShi.xinCaiLiao) {
    zhuanLiCountMap.series.push({
      key: "新材料",
      totalFlag: false,
      data: areaData.patentShenQingQuShi.xinCaiLiao.split(","),
    });
  }

  areaData.zhuanLiFenBu.map((item: any) => {
    // @ts-ignore
    pingjiMap.xAxis.push(item.score);
    // @ts-ignore
    pingjiMap.value.push(item.num);
    return pingjiMap;
  });
  areaData.zhuanLiPriceFenBu.map((item: any) => {
    // @ts-ignore
    jiageMap.xAxis.push(item.price);
    // @ts-ignore
    jiageMap.value.push(item.num);
    return jiageMap;
  });

  const jsjyTotalCount = areaData.tecTrade.years
    .split(",")
    .reduce(
      (partialSum: any, a: any) =>
        Number.parseInt(partialSum) + Number.parseInt(a),
      0
    );
  const allArray = areaData.tecTrade.all.split(",");

  const jsjyMap = {
    xAixs: areaData.tecTrade.years.split(","),
    legend: ["总数", "流入本市", "从本市流出", "从本地流转"],
    num: formatNum(allArray[allArray.length - 2]),
    series: [
      { key: "总数", totalFlag: true, data: areaData.tecTrade.all.split(",") },
      {
        key: "流入本市",
        totalFlag: false,
        data: areaData.tecTrade.in.split(","),
      },
      {
        key: "从本市流出",
        totalFlag: false,
        data: areaData.tecTrade.out.split(","),
      },
      {
        key: "从本地流转",
        totalFlag: false,
        data: areaData.tecTrade.local.split(","),
      },
    ],
  };
  // console.log(getCenterBlockData());
  return {
    qiyeFaMing: jfmMap,
    // 涨幅
    insPer: insPer,
    inCount,
    outCount,
    // 专利评级分布
    zhuanLiPingJiFengBu: pingjiMap,
    // 专利价格分布
    zhuanLiJiaGeFengBu: jiageMap,
    // 技术交易发生数量，
    jiShuJiao: jsjyMap,
    /* 实用专利百分比（现已改成发明专利）*/
    shiYongBaiFengBi: syzlMap,
    zhuanliCount: zhuanLiCountMap,
    /*申请企业数量初次*/
    firstQYCount: firstQYCountsMap,
    // 静态数据需要替换
    //centerData: getCenterBlockData(),
  };
}

const DataDashboard = () => {
  // give default areaName
  const areaName =
    useQuery().get("name") != null ? useQuery().get("name") : "上海";
  const { apiFactory } = getEnv();
  // let query = useQuery();
  const { hash } = useLocation();
  // get data
  // const areaData = getQueryData(hash, areaName);

/*  const viewData = handleData(areaData);
  const cardPropsArray = [
    {
      icon: cardCountIcon,
      title: "数量",
      value: viewData.shiYongBaiFengBi.totalCount,
    },
    /!*    { icon: cardUpIcon, title: "涨幅", value: viewData.insPer as any },*!/
    { icon: cardInIcon, title: "流入", value: viewData.inCount },
    { icon: cardOutIcon, title: "流出", value: viewData.outCount },
  ];*/

  const [viewData, setViewData] = useState<IHandleData>( );
  const [barAreaData, setBarAreaData] = useState<any>( );
  const [cardPropsArray, setCardPropsArray] = useState([{}]);

  useEffect(() => {
    // console.log(areaData);
    console.log(areaName);

    const initCityData =async () =>{
      const cityData =  await apiFactory.dataService.getCityData();
      const random = cityData.random;
      const currentCityItem = cityData.data.filter((item:{AUTHORITY_ID:string,NAME:string}) =>{
        if((areaName == '浦东' && item.NAME == '浦东新') || (item.NAME == areaName)) {
          return item;
        }
      })
      const dataDetail = await apiFactory.dataService.getDataDetail(random, currentCityItem[0].AUTHORITY_ID)
      const qushiData = await apiFactory.dataService.getDataQushi();
      qushiData.areaList.forEach((item: any) => {
        if(item.area === '浦东新') {
          item.area = '浦东';
        }
      });

     /* console.log('cityData', currentCityItem);
      console.log('dataDetail', dataDetail);
      console.log('qushiData', qushiData);*/
      const vd = handleData(dataDetail);
      setViewData(vd);
      setBarAreaData(qushiData);
      setCardPropsArray([
        {icon: cardCountIcon, title: "数量",value: vd.shiYongBaiFengBi.totalCount,},
   /*        { icon: cardUpIcon, title: "涨幅", value: viewData.insPer as any },*/
        { icon: cardInIcon, title: "流入", value: vd.inCount },
        { icon: cardOutIcon, title: "流出", value: vd.outCount },
      ]);
    }
    initCityData();
  },[]);

  const showNav = () => {
    (document.querySelector(".header") as HTMLDivElement).classList.remove(
      "leave"
    );
    (document.querySelector(".header") as HTMLDivElement).style.transform =
      "translateY(0)";
  };
  const hideNav = () => {
    (document.querySelector(".header") as HTMLDivElement).style.transform =
      "unset";
  };

  return (
    <div className="dataDashboard-div-container">
      <div
        className="hover-area"
        onMouseOver={showNav}
        onMouseLeave={hideNav}
      ></div>
      <DashboardTitle />

      <div className="dataDashboard-div-leftPanel">
        {viewData?.shiYongBaiFengBi &&   <MeterBlock
            upText={"发明专利"}
            lowText={"占总专利数量"}
            max={viewData?.shiYongBaiFengBi?.totalCount}
            // @ts-ignore
            progress={Number.parseFloat(viewData.shiYongBaiFengBi.percent) * 100}
        />}
        {viewData?.qiyeFaMing && <MeterBlock
            upText={"申请发明专利"}
            lowText={"企业数量占比"}
            max={viewData.qiyeFaMing.count}
            progress={viewData.qiyeFaMing.percent}
        />}
        {viewData?.zhuanliCount &&
        <StackAreaBlock
          style={{ height: "300px" }}
          title={"申请专利的数量，当前"}
          num={viewData.zhuanliCount.num + ""}
          xAixs={viewData.zhuanliCount.xAixs}
          legend={viewData.zhuanliCount.legend as any}
          series={viewData.zhuanliCount.series as any}
        />}
        {viewData?.firstQYCount &&
        <StackAreaBlock
          style={{ height: "242px" }}
          title={"申请专利的企业数量，当前"}
          num={viewData.firstQYCount.num + ""}
          xAixs={viewData.firstQYCount.xAixs}
          legend={viewData.firstQYCount.legend as any}
          series={viewData.firstQYCount.series as any}
        />}
      </div>

      <div className="dataDashboard-div-middlePanel">

        {
          cardPropsArray.map((item:any, index) => {
          return <Card key={index} {...item} />;
        })}
        {hash === "#area" ? (
            barAreaData && <HexagenBar areaList={barAreaData.areaList} />
        ) : (viewData?.shiYongBaiFengBi && barAreaData &&
          <MapChart
            shNum={ barAreaData.shData}
            areaList={barAreaData.areaList}
          />
        )}
      </div>
      <div className="dataDashboard-div-rightPanel">
        <NewsBlock />
        <WeatherBlock />
        {viewData?.zhuanLiPingJiFengBu &&
        <BarChartBlock
          title={"专利评级分布"}
          xAxis={viewData.zhuanLiPingJiFengBu.xAxis}
          value={viewData.zhuanLiPingJiFengBu.value}
        />}
        {viewData?.zhuanLiJiaGeFengBu &&
        <BarChartBlock
          title={"专利价格分布"}
          xAxis={viewData.zhuanLiJiaGeFengBu.xAxis}
          value={viewData.zhuanLiJiaGeFengBu.value}
        />}
        {viewData?.jiShuJiao &&
        <StackAreaBlock
          style={{ height: "392px" }}
          title={"技术交易发生数量，当前"}
          num={viewData.jiShuJiao.num + ""}
          xAixs={viewData.jiShuJiao.xAixs}
          legend={viewData.jiShuJiao.legend as any}
          series={viewData.jiShuJiao.series as any}
        />}

      </div>
    </div>
  );
};

export default DataDashboard;
