import React from "react";
import OuterMeter from "./outer-meter";
import InnerMeter from "./inner-meter";

interface TachoMeterProps {
  min?: number;
  max: number;
  upText: string;
  lowText: string;
  progress: number;
}

const MeterBlock: React.FC<TachoMeterProps> = (props: TachoMeterProps) => {
  return (
    <div className="leftPanel-div-tachometerBlock dashDashboard-block">
      <div className="tachometerBlock-div-meterChart">
        <div className="meterChart-div-progressContainer">
          <div className="meterChart-div-outerMeter">
            <OuterMeter max={props.max} />
          </div>
          <div className="meterChart-div-innerMeter">
            <InnerMeter />
          </div>
        </div>
      </div>
      <div className="tachometerBlock-div-info">
        <div className="meterInfo-div-supText infoText">{props.upText}</div>
        <div className="meterInfo-div-subText infoText">{props.lowText}</div>
        <div className="meterInfo-div-progress">
          {props.progress}
          <span className="meterInfo-span-progressUnit">%</span>
        </div>
      </div>
    </div>
  );
};

export default MeterBlock;
