import BaseService from "../core/base-service";
import StorageService, { StorageItems } from "../core/storage-service";

export default class AuthService extends BaseService {
  // TODO: change this to your route in server
  static route = "auth";
  private storage: StorageService = new StorageService();

  storeVcodeTimestamp() {
    this.storage.setItem(StorageItems.VCODE, new Date().getTime().toString());
  }

  getVcodeTimestamp() {
    const lastVcodeSendTime = this.storage.getItem(StorageItems.VCODE);
    if (
      lastVcodeSendTime &&
      new Date().getTime() - parseInt(lastVcodeSendTime) < 60000
    ) {
      return (
        60 -
        Math.floor((new Date().getTime() - parseInt(lastVcodeSendTime)) / 1000)
      );
    }
    return 0;
  }

  storeCredentials(token: string) {
    this.storage.setItem(StorageItems.TOKEN, token);
  }

  clearCredentials() {
    this.storage.clearItem(StorageItems.TOKEN);
  }

  getCredentials(item: StorageItems) {
    return this.storage.getItem(item);
  }

  /**
   * 判断token是否存在
   * @returns boolean
   */
  isAuthenticated() {
    return !!(
      this.getCredentials(StorageItems.TOKEN) ||
      this.getCredentials(StorageItems.GUEST)
    );
  }

  /**
   * 发送验证码
   * @param phone
   * @returns
   */
  async sendVcode(phone: string): Promise<any> {
    const response = await this.httpService.post<any, any>(`/api/patent_vcode`, {
      mobile: `+86.${phone}`,
      type: "login",
    });
    this.storeVcodeTimestamp();
    return response;
  }

  // TODO: Replace any here with your types in the below requests :)
  async loginWithToken(token: string) {
    const response = await this.httpService.post<any, any>(
      `${this.path}/login`
    );

    return response.data;
  }

  async login(principal: string, credentials: string): Promise<any> {
    const response = await this.httpService.postResponse<any>(`/api/login/003`, {
      principal: `[ph]+86.${principal}`,
      credentials: `{vc}${credentials ? credentials : 111111}`,
    });

    if (response.status === 200) {
      this.storage.setItem(StorageItems.USER, JSON.stringify(response.data));
    }

    return response;
  }

  async register(email: string, password: string): Promise<any> {
    const response = await this.httpService.post<any, any>(
      `${this.path}/register`,
      { username: email, password }
    );

    return response.data;
  }

  getLoginUser() {
    return this.storage.getUser()
      ? JSON.parse(this.storage.getUser() as string)
      : null;
  }
  logout() {
    this.storage.logout();
  }
}
