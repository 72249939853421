import React, { CSSProperties, useLayoutEffect, useState } from "react";
import { ReactECharts } from "../../echarts/echarts";

function getDefaultOption() {
  return {
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "none",
        label: {
          backgroundColor: "#6a7985",
        },
      },
    },
    legend: {
      orient: "vertical",
      right: 10,
      top: -2,
      itemWidth: 15,
      itemHeight: 10,
      icon: "circle",
      data: ["总数", "高端装备制造", "新一代信息技术", "新材料"],
    },
    grid: {
      left: "3%",
      right: "4%",
      height: "70%",
      bottom: "3%",
      containLabel: true,
    },
    xAxis: [
      {
        type: "category",
        boundaryGap: false,
        data: ["2014", "2015", "2016", "2017", "2018", "2019", "2020"],
      },
    ],
    yAxis: [
      {
        type: "value",
      },
    ],
    series: [
      {
        name: "总数",
        type: "line",
        stack: "Total",
        smooth: true,
        showSymbol: false,
        areaStyle: {},
        emphasis: {
          focus: "series",
        },
        data: [120, 132, 101, 134, 90, 230, 210],
      },
      {
        name: "高端装备制造",
        type: "line",
        smooth: true,
        showSymbol: false,
        data: [220, 182, 191, 234, 290, 330, 310],
      },
      {
        name: "新一代信息技术",
        type: "line",
        smooth: true,
        showSymbol: false,
        data: [150, 232, 201, 154, 190, 330, 410],
      },
      {
        name: "新材料",
        type: "line",
        smooth: true,
        showSymbol: false,
        data: [320, 332, 301, 334, 390, 330, 320],
      },
    ],
  };
}

function getChartOption(params: StackAreaBlockProps) {
  const currentOption = getDefaultOption();
  // @ts-ignore
  currentOption.legend.data = params.legend;
  // @ts-ignore
  currentOption.xAxis[0].data = params.xAixs;
  const series: (
    | {
        name: string;
        type: string;
        stack: string;
        smooth: boolean;
        showSymbol: boolean;
        areaStyle: {};
        emphasis: { focus: string };
        data: number[];
      }
    | {
        name: string;
        type: string;
        smooth: boolean;
        showSymbol: boolean;
        data: number[];
        stack?: undefined;
        areaStyle?: undefined;
        emphasis?: undefined;
      }
  )[] = [];
  // @ts-ignore
  params.series.map((item) => {
    if (item.totalFlag) {
      series.push({
        name: item.key,
        type: "line",
        stack: "Total",
        smooth: true,
        showSymbol: false,
        areaStyle: {},
        emphasis: {
          focus: "series",
        },
        data: item.data,
      });
    } else {
      series.push({
        name: item.key,
        type: "line",
        smooth: true,
        showSymbol: false,
        data: item.data,
      });
    }
  });
  currentOption.series = series;
  return currentOption;
}

const style = {
  width: "100%",
  height: "100%",
};

export interface StackAreaChartProps {
  key: string;
  data: [];
  totalFlag: boolean;
}

export interface StackAreaBlockProps {
  style?: CSSProperties;
  title: string;
  num: string;
  xAixs: [];
  legend: [];
  series: Array<StackAreaChartProps>;
}

const StackAreaBlock: React.FC<StackAreaBlockProps> = (props) => {
  const option = getChartOption(props);
  return (
    <div
      className="leftPanel-div-stackAreaBlock dashDashboard-block"
      style={{ ...props.style }}
    >
      <div className="stackArea-div-title">
        {props.title}
        <label>{props.num}</label>
      </div>
      <ReactECharts option={option as any} style={style} />
    </div>
  );
};

export default StackAreaBlock;
