/* eslint-disable */
/* tslint:disable */

import React, {useEffect, useState} from "react";
import { useLocation } from "react-router-dom";
import DashboardTitle from "./title/title";
import StackAreaBlock from "../../components/charts/stack-area/stack-area-block";
import Card from "../../components/charts/card/card";
import MapChart from "../../components/charts/map/map";
import NewsBlock from "./news/news";
import WeatherBlock from "./weather/weather";
import HexagenBar from "../../components/charts/hexagon/hexagenBar";
import BarChartBlock from "../../components/charts/bar/bar";

import { formatNum } from "../../utils/NumberUtil";
import {getEnv} from "../../helpers/mobx-easy-wrapper";
import {IData, IHandleData, IYPData} from "./idata";
import PieChartBlock from "../../components/charts/pie/pie-chart-block";
import MBarChartBlock from "../../components/charts/bar/multi-bar";
import LineBlock from "../../components/charts/stack-area/line-block";
import YPDashboardTitle from "./title/yp_title";

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}


function handleData(areaData: any) {
  //专利评级分布
  const pingjiMap = {
    xAxis: [],
    value: [],
  };
  //专利价格分布
  const jiageMap = {
    xAxis: [],
    value: [],
  };
  const shiYongCount = Number.parseInt(areaData.patentType.shiYong);
  const faMingCount = Number.parseInt(areaData.patentType.faMing);

  /**  环形图 ***/
  // 实用专利百分比
  const fmPercent = (faMingCount / (shiYongCount + faMingCount)).toFixed(2);
  const circleChart = {
    percent: fmPercent,
    sy: {
      percent: fmPercent,
      count: faMingCount
    },
    fm: {
      percent: 1 - Number.parseFloat(fmPercent),
      count: shiYongCount
    },
    totalCount: shiYongCount + faMingCount,
  };

  /**  专利趋势柱状图 ***/

  const years: any[] = [];
  const nums: any[] = [];
  areaData.patentQuShi.forEach((qs: { year: any; num: any; }) => {
    years.push(qs.year);
    nums.push(qs.num);
  })
  const labels = ['数量']
  const xAxis = years;
  const value = [
    {title:labels[0], data: nums}
  ]
  const mBarChart = {
    labels , xAxis, value
  }

  /** 每年新增发明人 ***/
  const fmRenLabels = ['发明数量', '新增发明数量'];
  const fmRenXAxis = areaData.addFaMingRen.years.split(',');
  const fmRenValue = [
    {title:fmRenLabels[0], data: areaData.addFaMingRen.faMingNums.split(',')},
    {title:fmRenLabels[1], data: areaData.addFaMingRen.addFaMingNums.split(',')},
  ];
  const fmRenBarChart = {
    labels: fmRenLabels , xAxis: fmRenXAxis, value: fmRenValue
  };


  /** 流入流出图 ***/
  const allArray = areaData.tecTrade.all.split(",");
  const jsjyMap = {
    xAixs: areaData.tecTrade.years.split(","),
    legend: ["总数", "流入本市", "从本市流出", "从本地流转"],
    num: formatNum(allArray[allArray.length - 2]),
    series: [
      { key: "总数", totalFlag: true, data: areaData.tecTrade.all.split(",") },
      {
        key: "流入本市",
        totalFlag: false,
        data: areaData.tecTrade.in.split(","),
      },
      {
        key: "从本市流出",
        totalFlag: false,
        data: areaData.tecTrade.out.split(","),
      },
      {
        key: "从本地流转",
        totalFlag: false,
        data: areaData.tecTrade.local.split(","),
      },
    ],
  };

  /** 战略新兴产业申请专利数量 ***/
  const totalCountArray: any[] = [];
  areaData.patentQuShi.map((item: any) => {
    totalCountArray.push(Number.parseInt(item.num));
  });
  const zlZLMap = {
    xAixs: areaData.patentShenQingQuShi.years.split(','),
    legend: ["总数", "高端装备制造", "新一代信息技术", "新材料"],
    num: formatNum(totalCountArray[totalCountArray.length - 2]),
    series: [
      { key: "总数", totalFlag: true, data: totalCountArray },
      {
        key: "高端装备制造",
        totalFlag: false,
        data: areaData.patentShenQingQuShi.gaoDuanZhiZhao.split(","),
      },
      {
        key: "新一代信息技术",
        totalFlag: false,
        data: areaData.patentShenQingQuShi.xinXiJiShu.split(","),
      },
      {
        key: "新材料",
        totalFlag: false,
        data: areaData.patentShenQingQuShi.xinCaiLiao.split(","),
      },
    ],
  };

  /** 战略新兴企业数量 ***/
  const zlQyMap = {
    xAixs: areaData.addQiYeNumsFa.years.split(','),
    legend: [ "新增企业", "企业数量"],
    series: [
      {
        key: "新增企业",
        totalFlag: false,
        data: areaData.addQiYeNumsFa.addQiYeNums.split(","),
      },
      {
        key: "企业数量",
        totalFlag: false,
        data: areaData.addQiYeNumsFa.qiYeNums.split(","),
      }
    ]
  };




/*
  //申请企业数量初次
  const firstQYTotalCount = areaData.addQiYeNumsFa.qiYeNums.split(",");
  const firstQYCountsMap = {
    xAixs: areaData.addQiYeNumsFa.years.split(","),
    legend: ["总数", "初次申请专利"],
    num: formatNum(firstQYTotalCount[firstQYTotalCount.length - 2]),
    series: [
      {
        key: "总数",
        totalFlag: true,
        data: areaData.addQiYeNumsFa.qiYeNums.split(","),
      },
      {
        key: "初次申请专利",
        totalFlag: false,
        data: areaData.addQiYeNumsFa.addQiYeNums.split(","),
      },
    ],
  };

  const totalCountArray: any[] = [];
  console.log('areaData.patentQuShi', areaData.patentQuShi)
  areaData.patentQuShi.map((item: any) => {
    totalCountArray.push(Number.parseInt(item.num));
  });

  //涨幅
  const year2021 = Number.parseInt(
    areaData.patentQuShi[areaData.patentQuShi.length - 2].num
  );
  const year2020 = Number.parseInt(
    areaData.patentQuShi[areaData.patentQuShi.length - 3].num
  );
  const insPer = (((year2021 - year2020) / year2020) * 100).toFixed(0) + "%";

  const inArray = areaData.tecTrade.in.split(",");
  const outArray = areaData.tecTrade.out.split(",");
  // 流入 流出
  const inCount = inArray[inArray.length - 2];
  const outCount = outArray[outArray.length - 2];

  //仅仅发明 企业占比
  const addQiYeNums = areaData.addQiYeNumsFaMing.addQiYeNums.split(",");
  const qyNums = areaData.addQiYeNumsFaMing.qiYeNums.split(",");

  const jfm = Number.parseInt(
    (Number.parseInt(addQiYeNums[addQiYeNums.length - 2]) /
      Number.parseInt(qyNums[qyNums.length - 2])) *
      100 +
      ""
  );

  const jfmMap = {
    percent: jfm,
    count: qyNums[qyNums.length - 2],
  };

  const zhuanLiCountMap = {
    xAixs: areaData.patentShenQingQuShi.years.split(","),
    legend: ["总数", "高端装备制造", "新一代信息技术", "新材料"],
    num: formatNum(totalCountArray[totalCountArray.length - 2]),
    series: [
      { key: "总数", totalFlag: true, data: totalCountArray },
      {
        key: "高端装备制造",
        totalFlag: false,
        data: areaData.patentShenQingQuShi.gaoDuanZhiZhao.split(","),
      },
      {
        key: "新一代信息技术",
        totalFlag: false,
        data: areaData.patentShenQingQuShi.xinXiJiShu.split(","),
      },
    ],
  };
  // 区级没有新材料
  if (areaData.patentShenQingQuShi.xinCaiLiao) {
    zhuanLiCountMap.series.push({
      key: "新材料",
      totalFlag: false,
      data: areaData.patentShenQingQuShi.xinCaiLiao.split(","),
    });
  }

  areaData.zhuanLiFenBu.map((item: any) => {
    // @ts-ignore
    pingjiMap.xAxis.push(item.score);
    // @ts-ignore
    pingjiMap.value.push(item.num);
    return pingjiMap;
  });
  areaData.zhuanLiPriceFenBu.map((item: any) => {
    // @ts-ignore
    jiageMap.xAxis.push(item.price);
    // @ts-ignore
    jiageMap.value.push(item.num);
    return jiageMap;
  });

  const jsjyTotalCount = areaData.tecTrade.years
    .split(",")
    .reduce(
      (partialSum: any, a: any) =>
        Number.parseInt(partialSum) + Number.parseInt(a),
      0
    );
  const allArray = areaData.tecTrade.all.split(",");

  const jsjyMap = {
    xAixs: areaData.tecTrade.years.split(","),
    legend: ["总数", "流入本市", "从本市流出", "从本地流转"],
    num: formatNum(allArray[allArray.length - 2]),
    series: [
      { key: "总数", totalFlag: true, data: areaData.tecTrade.all.split(",") },
      {
        key: "流入本市",
        totalFlag: false,
        data: areaData.tecTrade.in.split(","),
      },
      {
        key: "从本市流出",
        totalFlag: false,
        data: areaData.tecTrade.out.split(","),
      },
      {
        key: "从本地流转",
        totalFlag: false,
        data: areaData.tecTrade.local.split(","),
      },
    ],
  };*/
  return {
   /* qiyeFaMing: jfmMap,
    // 涨幅
    insPer: insPer,
    inCount,
    outCount,
    // 专利评级分布
    zhuanLiPingJiFengBu: pingjiMap,
    // 专利价格分布
    zhuanLiJiaGeFengBu: jiageMap,
    // 技术交易发生数量，
    jiShuJiao: jsjyMap,
    zhuanliCount: zhuanLiCountMap,
    /!*申请企业数量初次*!/
    firstQYCount: firstQYCountsMap,*/
    // 技术交易发生数量，
    jiShuJiao: jsjyMap,
    qushiChart: mBarChart,
    /* 实用专利百分比（现已改成发明专利）*/
    circleChart: circleChart,
    fmRenBarChart: fmRenBarChart,
    tradeData:  areaData.tradeData,
    zhuanliCount: zlZLMap,
    qyCount: zlQyMap
  };
}

const YPDataDashboard = () => {
  // give default areaName
  const areaName =
    useQuery().get("name") != null ? useQuery().get("name") : "上海";
  const { apiFactory } = getEnv();
  // let query = useQuery();
  const { hash } = useLocation();
  // get data
  // const areaData = getQueryData(hash, areaName);

/*  const viewData = handleData(areaData);
  const cardPropsArray = [
    {
      icon: cardCountIcon,
      title: "数量",
      value: viewData.shiYongBaiFengBi.totalCount,
    },
    /!*    { icon: cardUpIcon, title: "涨幅", value: viewData.insPer as any },*!/
    { icon: cardInIcon, title: "流入", value: viewData.inCount },
    { icon: cardOutIcon, title: "流出", value: viewData.outCount },
  ];*/

  const [viewData, setViewData] = useState<IYPData>( );
  const [barAreaData, setBarAreaData] = useState<any>( );
  const [cardPropsArray, setCardPropsArray] = useState([{}]);

  useEffect(() => {
    // console.log(areaData);

    function scrollerAnimation() {
      const parent = document.querySelector(".yp-list") as HTMLDivElement;
      setInterval(function () {
        if ((parent.scrollTop + parent.clientHeight + 10) >= parent.scrollHeight) {
          if(parent.querySelector('ul > li')) {
            const dataElement = parent!.querySelector('ul')!.cloneNode(true);
            parent.append(dataElement);
          }

        } else {
          parent.scrollTop++;
        }
      }, 40);
    }

    const initCityData =async () =>{
      const ypData = await apiFactory.dataService.getYPData();
      const dataMap = handleData(ypData);
      /** 中间区域卡片 ***/
      setCardPropsArray([
        {icon: null, title: "发明专利数量",value: ypData.patentType.faMing},
        { icon: null, title: "实用新型数量", value: ypData.patentType.shiYong }
      ]);
      //dataMap.circleChart;
      setViewData(dataMap as any);
    }
    scrollerAnimation();
    initCityData();
  },[]);

  const showNav = () => {
    (document.querySelector(".header") as HTMLDivElement).classList.remove(
      "leave"
    );
    (document.querySelector(".header") as HTMLDivElement).style.transform =
      "translateY(0)";
  };
  const hideNav = () => {
    (document.querySelector(".header") as HTMLDivElement).style.transform =
      "unset";
  };

  return (
    <div className="dataDashboard-div-container" style={{marginTop: '-140px', height: '100vh'}}>
    {/*  <div
        className="hover-area"
        onMouseOver={showNav}
        onMouseLeave={hideNav}
      ></div>*/}
      <YPDashboardTitle />

      <div className="dataDashboard-div-leftPanel" style={{height:'calc(100% - 105px)'}}>
        <PieChartBlock title={'发明、实用饼图'} fmMap={viewData?.circleChart?.fm} syMap={viewData?.circleChart?.sy} />

        <MBarChartBlock
            title={"专利申请趋势"}
            xAxis={viewData?.qushiChart?.xAxis}
            labels={viewData?.qushiChart?.labels}
            value={viewData?.qushiChart?.value}
        />

        <LineBlock
            style={{ height: "350px" }}
            title={"战略新兴产业申请专利数量"}
            num={""}
            xAixs={viewData?.zhuanliCount?.xAixs}
            legend={viewData?.zhuanliCount?.legend}
            series={viewData?.zhuanliCount?.series}
        />

      </div>

      <div className="dataDashboard-div-middlePanel yp-mid"  style={{height:'calc(100% - 105px)'}}>
        {
          cardPropsArray.map((item:any, index) => {
            return <Card key={index} {...item} />;
          })}
          <div className="yp-list">
            <ul>
              {
                viewData?.tradeData.map((item: any) =>{
                  return (<li><p>{item.xmbh} / {item.type} / {item.bdlxzh} / {item.jylxzh} / {item.jslyflzh}<br/>
                  {item.xmmc}  </p> </li>)
                })
              }

            </ul>
          </div>
      </div>
      <div className="dataDashboard-div-rightPanel"  style={{height:'calc(100% - 115px)'}}>
        <MBarChartBlock
            title={"每年新增发明人"}
            style={{ height: "32%", marginTop: '10px', padding: '0px 0px 5px 10px' }}
            xAxis={viewData?.fmRenBarChart?.xAxis}
            labels={viewData?.fmRenBarChart?.labels}
            value={viewData?.fmRenBarChart?.value}
        />
      <LineBlock
            style={{ height: "32%", marginTop: '10px', paddingTop: 0  }}
            title={"战略新兴产业企业数量"}
            num={""}
            xAixs={viewData?.qyCount?.xAixs}
            legend={viewData?.qyCount?.legend}
            series={viewData?.qyCount?.series}
        />
        {viewData?.jiShuJiao &&
        <StackAreaBlock
            style={{ height: "32%", marginTop: '10px', paddingTop: 0  }}
            title={"技术交易发生数量，当前"}
            num={viewData?.jiShuJiao.num + ""}
            xAixs={viewData?.jiShuJiao.xAixs}
            legend={viewData?.jiShuJiao.legend as any}
            series={viewData?.jiShuJiao.series as any}
        />}

      </div>
    </div>
  );
};

export default YPDataDashboard;
