/* eslint-disable */
/* tslint:disable */

import header from "../../../images/header.png";
import React from "react";

const juryList = [{}, {}, {}, {}, {}, {}];

const JuryCard = () => {
  return (
    <div className="jury-div-cards">
      {juryList.map((item, index) => {
        return (
          <div className="jury-item">
            <div className="jury-div-cardItem">
              <div className="jury__header">
                <img src={header} />
              </div>
              <div className="cover-zhName">陈媛青</div>
              <div className="cover-enName">CHENG YUANQING</div>

              <div className="jury__info">
                <ul>
                  <li className="zhName">陈媛青</li>
                  <li className="enName">CHENGYUANQING</li>
                  <li className="info">
                    <p>
                      联想集团 全球法务 知识产权总监 荣获2012年“
                      “中国杰出知识产权经理人 2013中国知识产权风云人物“杰出管理
                      2015年“中关村创新领军人才” 2016年“年
                      度企业知识产权工作先进个人'
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default JuryCard;
