/* eslint-disable */
/* tslint:disable */

import React from "react";
import { ReactECharts, ReactEChartsProps } from "../../echarts/echarts";

function getChartOption(param: MBarChartProps) {

  const options: any = [];
  const config = {
    rotate: 90,
    align: 'left',
    verticalAlign: 'middle',
    position: 'insideBottom',
    distance: 15
  };
  const labelOption = {
    show: false,
    position: config.position,
    distance: config.distance,
    align: config.align,
    verticalAlign: config.verticalAlign,
    rotate: config.rotate,
    formatter: '{c}  {name|{a}}',
    fontSize: 16,
    rich: {
      name: {}
    }
  };

  if(param.value) {
    (param.value as any).forEach((item:{title: string, data: []}) => {
      const itemOption = {
        name: item.title,
        type: 'bar',
        barGap: 0,
        label: labelOption,
        emphasis: {
          focus: 'series'
        },
        data: item.data
      };
      options.push(itemOption);
    })
  }


  const option = {
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow'
      }
    },
    legend: {
      data: param.labels
    },
    toolbox: {
      show: false
    },
    xAxis: [
      {
        type: 'category',
        axisTick: { show: false },
  /*      data: ['2012', '2013', '2014', '2015', '2016']*/
        data: param.xAxis
      }
    ],
    yAxis: [
      {
        type: 'value'
      }
    ],
    series: options as any
  };


  return option;
}

const style = {
  height: "100%",
  marginLeft: "-10px",
  width: "calc(100% + 25px)",
};

export interface MBarChartProps {
  title: string;
  /*年份*/
  xAxis?: string[];
  /*品类*/
  labels: string[];
  value?: {title: string, data:[]  }[];
  style?:any;
}

const MBarChartBlock: React.FC<MBarChartProps> = (props) => {
  return (
    <div className="barChart-div-block dashDashboard-block" style={{width: '100%' , ...props.style}}>
      <div className="barChart-div-title" style={{top: '1px', left:'unset', width:'100%', textAlign:'center'}}>{props.title}</div>
      <ReactECharts option={getChartOption(props) as any} style={style} />
    </div>
  );
};

export default MBarChartBlock;
