import React, { useEffect, useState } from "react";
import { getEnv } from "../../../helpers/mobx-easy-wrapper";
import { message } from "antd";

export interface LoginProps {
  closeEvent: Function;
}

const Login = (props: LoginProps) => {
  const [state, setState] = useState(0);
  const [phone, setPhone] = useState("");
  const [code, setCode] = useState("");
  const [time, setTime] = useState(0);
  const { apiFactory } = getEnv();
  let timer = 0;
  const handleRemainTime = () => {
    const remainingTime = apiFactory.authService.getVcodeTimestamp();
    setTime(apiFactory.authService.getVcodeTimestamp());
    timer = 0;
    //剩余时间》60开始重新计时
    if (remainingTime > 0) {
      timer = window.setInterval(() => {
        setTime((preval) => {
          if (preval === 1) {
            window.clearInterval(timer);
          }
          return preval - 1;
        });
      }, 1000);
    }
  };
  const sendVCode = async () => {
    if (time > 0) {
      return;
    }
    try {
      await apiFactory.authService.sendVcode(phone);
      message.success("发送成功");
    } catch (e) {
      message.error("发送失败");
    }
    handleRemainTime();
  };

  const loginSubmit = async () => {
    try {
      const response = await apiFactory.authService.login(phone, code);
      if (response.status != 200) {
        message.error(response.data.message);
        return;
      }
      message.success("登录成功");
      props.closeEvent();
      setTimeout(() => {
        window.location.reload();
      }, 600);
    } catch (error: any) {
      message.error(JSON.parse(error.request.response).message);
    }
  };

  useEffect(() => {
    handleRemainTime();
    return () => {
      window.clearInterval(timer);
    };
  }, []);

  return (
    <div className="loginContainer">
      <div className="loginBox">
        <div className="signContent">
          <div className="signContainer">
            <form
              action=""
              className="loginForm"
              id="form_key"
              data-module="smsFrom"
            >
              <div className="tabBox">
                <div className="tabBoxSwitch">
                  <ul className="tabBoxSwitchUl">
                    <li
                      className={`${state === 0 ? "tab-active" : ""}`}
                      onClick={() => {
                        setState(0);
                      }}
                    >
                      免密码登录
                    </li>
                  </ul>
                  <div
                    className="ercode_tab swicth-ercode"
                    onClick={() => {
                      props.closeEvent();
                    }}
                  >
                    <svg
                      className="icon"
                      viewBox="0 0 1024 1024"
                      version="1.1"
                      xmlns="http://www.w3.org/2000/svg"
                      p-id="1979"
                      width="24"
                      height="24"
                    >
                      <path
                        d="M544.448 499.2l284.576-284.576a32 32 0 0 0-45.248-45.248L499.2 453.952 214.624 169.376a32 32 0 0 0-45.248 45.248l284.576 284.576-284.576 284.576a32 32 0 0 0 45.248 45.248l284.576-284.576 284.576 284.576a31.904 31.904 0 0 0 45.248 0 32 32 0 0 0 0-45.248L544.448 499.2z"
                        p-id="1980"
                        fill="#8590a6"
                      ></path>
                    </svg>
                  </div>
                </div>
              </div>
              <div className="tabContent">
                <div
                  className={`tabcont  tabContentPhone ${
                    state === 0 ? "active" : ""
                  }`}
                >
                  <div className="tabcontent">
                    <div className="phoneBox">
                      <div className="phoneGroup">
                        <button
                          className="phone-btn selectBtn"
                          type="button"
                          data-type="selected"
                        >
                          <span>中国+86</span>
                          <svg
                            className="Zi--Select Select-arrow"
                            fill="currentColor"
                            viewBox="0 0 24 24"
                          >
                            <path
                              d="M12 16.183l2.716-2.966a.757.757 0 0 1 1.064.001.738.738 0 0 1 0 1.052l-3.247 3.512a.758.758 0 0 1-1.064 0L8.22 14.27a.738.738 0 0 1 0-1.052.758.758 0 0 1 1.063 0L12 16.183zm0-9.365L9.284 9.782a.758.758 0 0 1-1.064 0 .738.738 0 0 1 0-1.052l3.248-3.512a.758.758 0 0 1 1.065 0L15.78 8.73a.738.738 0 0 1 0 1.052.757.757 0 0 1-1.063.001L12 6.818z"
                              fill-rule="evenodd"
                            ></path>
                          </svg>
                        </button>
                      </div>
                      <div
                        className="selectConentent"
                        style={{ display: "none;" }}
                      >
                        <div className="selectOptions"></div>
                      </div>
                    </div>
                    <span className="line-show"></span>
                    <div className="phoneInputGroup">
                      <label
                        htmlFor=""
                        className="inputLabel phoneLabel isShow"
                        data-content={phone ? "" : "请输入手机号"}
                      >
                        <input
                          type="number"
                          name=""
                          id="phoneInput"
                          className="inputStyle phoneInput"
                          placeholder=" "
                          autoComplete="off"
                          value={phone}
                          onChange={(e) => setPhone(e.target.value)}
                        />
                      </label>
                    </div>
                  </div>
                  <div className="tabcontent">
                    <div className="phoneInputGroup">
                      <label
                        htmlFor=""
                        className="inputLabel isShow"
                        data-content={code ? "" : "请输入短信验证码"}
                      >
                        <input
                          type="number"
                          name=""
                          id="msgInput"
                          className="inputStyle msgInput"
                          placeholder=" "
                          autoComplete="off"
                          value={code}
                          onChange={(e) => setCode(e.target.value)}
                        />
                      </label>
                    </div>
                    <div className="phoneGroup">
                      <button
                        className="phone-btn msgBtn"
                        type="button"
                        onClick={sendVCode}
                      >
                        {time > 0 ? time : "发送短信验证码"}
                      </button>
                    </div>
                  </div>
                </div>

                <div
                  className={`tabcont  tabContentAccount ${
                    state === 1 ? "active" : ""
                  }`}
                >
                  <div className="tabcontent">
                    <div className="login-out-phoneBox">
                      <div className="phoneBox">
                        <div className="phoneGroup">
                          <button
                            className="phone-btn selectBtn"
                            type="button"
                            data-type="selected"
                          >
                            <span>中国+86</span>
                          </button>
                        </div>
                        <div
                          className="selectConentent"
                          style={{ display: "none;" }}
                        >
                          <div className="selectOptions"></div>
                        </div>
                      </div>
                      <span className="line-show"></span>
                    </div>
                    <div className="phoneInputGroup">
                      <label htmlFor="" className="inputLabel phoneLabel">
                        <input
                          type="text"
                          value=""
                          name=""
                          id="accountUser"
                          className="inputStyle accountUsername"
                          placeholder="手机号或邮箱"
                          autoComplete="off"
                        />
                      </label>
                    </div>
                  </div>
                  <div className="tabcontent">
                    <div className="phoneInputGroup">
                      <label htmlFor="" className="inputLabel phoneLabel">
                        <input
                          type="password"
                          value=""
                          name=""
                          id="accountpwd"
                          className="inputStyle accountPwd"
                          placeholder="密码"
                          autoComplete="off"
                        />
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <button
                id="submit"
                type="button"
                data-type="smsSubmit"
                onClick={loginSubmit}
                className={
                  code && phone
                    ? "button fromSubmit"
                    : "button fromSubmit disabled"
                }
              >
                注册/登录
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
