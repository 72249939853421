import React from "react";

const InnerMeter = () => {
  return (
      <div className="small-circular">
          <div className="small-circular circular">
              <div className="inner"></div>
              <div className="circle">
                  <div className="bar left">
                      <div className="progress"></div>
                  </div>
                  {/*  <div className="bar right">
                                  <div className="progress"></div>
                              </div>*/}
              </div>
          </div>
      </div>
  );
};

export default InnerMeter;
