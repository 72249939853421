import React from "react";
import logo from "../../../images/footerLogo.png";
import ypLogo from "../../../images/yp_logo.svg";

const YPDashboardTitle = () => {
  return (
    <div className="dataDashboard-div-titleContainer" style={{height: '80px', marginTop: '0px'}}>
      <div className="titleContainer-div-separator" style={{height: '100%',border:'none',    display: 'flex',
          alignItems: 'center'}}><a><img src={logo} /></a></div>
      <div className="titleContainer-div-content" style={{fontSize: '34px', fontWeight: 'bold'}}>
        <a href="/">上海市专利技术推广运用平台(杨浦服务站)</a>
      </div>
      <div className="titleContainer-div-separator" style={{height: '100%',border:'none'}}><img src={ypLogo} /></div>
    </div>
  );
};

export default YPDashboardTitle;
