import React, { useEffect, useState } from "react";

import artImg1 from "../../images/artboard-1.png";
import artImg2 from "../../images/artboard-2.png";
import artImg3 from "../../images/artboard-3.png";
import artImg4 from "../../images/artboard-4.png";

const Service = () => {
  const [index, setIndex] = useState(0);
  const scrollTo = (index: number) => {
    setIndex(index);
  };
  let timer = 0;
  useEffect(() => {
    timer = window.setInterval(() => {
      setIndex((preIndex) => {
        return preIndex === 3 ? 0 : preIndex + 1;
      });
    }, 3000);

    return () => {
      clearInterval(timer);
    };
  }, []);
  return (
    <div className="home-div-serviceContainer">
      {/* <div className="service-div-title">
        <div className="enTitle">SERVICE</div>
        <div className="zhTitle">服务</div>
      </div>*/}
      <div className="service-div-banner" id="serviceBanner">
        <div className="service-navContainer">
          <div
            className="item-activeSign"
            style={{ left: `${index * 92}px` }}
          ></div>
          <div
            className="service-navItem"
            onClick={() => {
              scrollTo(0);
            }}
          >
            价格分析
          </div>
          <div
            className="service-navItem"
            onClick={() => {
              scrollTo(1);
            }}
          >
            交易分析
          </div>
          <div
            className="service-navItem"
            onClick={() => {
              scrollTo(2);
            }}
          >
            价值分析
          </div>
          <div
            className="service-navItem"
            onClick={() => {
              scrollTo(3);
            }}
          >
            融资分析
          </div>
        </div>

        <div
          className="serviceBanner-item"
          style={{ transform: `translateX(${(0 - index) * 100}%)` }}
        >
          <div className="serviceTitle-en">SERVICE</div>
          <div className="serviceTitle-zh">服务</div>
          <div className="bannerTitle">专利市场价格分析报告</div>
          <div className="bannerSubTitle">
            根据市场法、基于市场历史交易记录，提供目标专利的交易价格区间。
          </div>
          <div className="service-coverImg">
            <img src={artImg1} />
          </div>
        </div>
        <div
          className="serviceBanner-item"
          style={{ transform: `translateX(${(0 - index) * 100}%)` }}
        >
          <div className="serviceTitle-en">SERVICE</div>
          <div className="serviceTitle-zh">服务</div>
          <div className="bannerTitle">专利市场交易分析报告</div>
          <div className="bannerSubTitle">
            基于大数据，分析目标专利所属行业的专利转让现状，为专利持有人提供转化参考。
          </div>
          <div className="service-coverImg">
            <img src={artImg2} />
          </div>
        </div>
        <div
          className="serviceBanner-item"
          style={{ transform: `translateX(${(0 - index) * 100}%)` }}
        >
          <div className="serviceTitle-en">SERVICE</div>
          <div className="serviceTitle-zh">服务</div>
          <div className="bannerTitle">企业专利资产价值分析报告</div>
          <div className="bannerSubTitle">
            从专利资产管理角度，突破专利数量多、评估难的局限，为企业分析专利资产价值。
          </div>
          <div className="service-coverImg">
            <img src={artImg3} />
          </div>
        </div>
        <div
          className="serviceBanner-item"
          style={{ transform: `translateX(${(0 - index) * 100}%)` }}
        >
          <div className="serviceTitle-en">SERVICE</div>
          <div className="serviceTitle-zh">服务</div>
          <div className="bannerTitle">企业融资技术专利评价建议报告</div>
          <div className="bannerSubTitle">
            基于市场融资的通行规则，从专利质押角度，为银行提供可靠的企业专利价值分析建议。
          </div>
          <div className="service-coverImg">
            <img src={artImg4} />
          </div>
        </div>
      </div>

      <div className="service-div-cards">
        <div className="cards__item">
          <div className="cards-icon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="96"
              height="96"
              viewBox="0 0 96 96"
            >
              <g
                transform="translate(-360 -1930.435)"
                opacity="0.2"
                className="svg-border"
              >
                <rect
                  width="96"
                  height="96"
                  rx="10"
                  transform="translate(360 1930.435)"
                  fill="#333"
                  className="svg-fill"
                />
                <g>
                  <path
                    d="M436.062,2000.484h-2v-28.063a8.041,8.041,0,0,0-8.018-8.017H414.013V1960.4a8.041,8.041,0,0,0-8.017-8.018H389.96a8.041,8.041,0,0,0-8.018,8.018v40.089h-2a2,2,0,1,0,0,4.009h56.124a2,2,0,1,0,0-4.009ZM410,1964.4v36.08H385.951V1960.4a4.021,4.021,0,0,1,4.009-4.009H406A4.02,4.02,0,0,1,410,1960.4Zm20.045,36.08H414.013v-32.071H426.04a4.021,4.021,0,0,1,4.009,4.008Zm-26.058-36.08H391.964a2,2,0,0,0,0,4.009h12.027a2,2,0,1,0,0-4.009Zm0,12.026H391.964a2,2,0,0,0,0,4.009h12.027a2,2,0,1,0,0-4.009Zm0,12.027H391.964a2,2,0,0,0,0,4.009h12.027a2,2,0,1,0,0-4.009Zm20.045-12.027h-4.009a2,2,0,1,0,0,4.009h4.009a2,2,0,0,0,0-4.009Zm0,12.027h-4.009a2,2,0,1,0,0,4.009h4.009a2,2,0,0,0,0-4.009Z"
                    fill="#fff"
                  />
                </g>
              </g>
            </svg>
          </div>
          <div className="cards-title">技术交易<div className="cards-title-en">Tech-Exchange</div></div>
          <div className="cards-desc">
            国家级常设技术交易市场，提供一站式技术交易与结算鉴证服务
          </div>
        </div>

        <div className="cards__item">
          <div className="cards-icon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="101"
              height="101"
              viewBox="0 0 101 101"
            >
              <rect
                fill="rgba(51,51,51, 0.2)"
                className="svg-fill"
                width="100"
                height="100"
                rx="10"
                transform="translate(0.5 0.5)"
                stroke="#fff"
                stroke-miterlimit="10"
                stroke-width="1"
              />
              <path
                d="M776,1947.237a31.2,31.2,0,1,0,31.2,31.2A31.193,31.193,0,0,0,776,1947.237Zm26.888,29.118h-8.24c-.358-9.006-2.987-16.963-6.964-22.275A27,27,0,0,1,802.888,1976.355Zm-12.433,0H778.08V1951.7C784.764,1953.485,789.931,1963.7,790.455,1976.355ZM773.92,1951.7v24.65H761.545C762.069,1963.7,767.236,1953.485,773.92,1951.7Zm-9.6,2.375c-3.981,5.312-6.606,13.269-6.964,22.275h-8.244A27,27,0,0,1,764.32,1954.08Zm-15.208,26.435h8.24c.358,9.005,2.987,16.963,6.964,22.274A27,27,0,0,1,749.112,1980.515Zm12.433,0H773.92v24.65C767.236,2003.384,762.069,1993.168,761.545,1980.515Zm16.535,24.65v-24.65h12.375C789.931,1993.168,784.764,2003.384,778.08,2005.165Zm9.6-2.376c3.981-5.311,6.606-13.269,6.964-22.274h8.24A26.984,26.984,0,0,1,787.68,2002.789Z"
                transform="translate(-725.148 -1930.237)"
                fill="#fff"
              />
            </svg>
          </div>
          <div className="cards-title">科创金融<div className="cards-title-en">Fintech Innovation</div></div>
          <div className="cards-desc">
            金融赋能专利技术的价值发现、市场化评估和资源配置
          </div>
        </div>

        <div className="cards__item">
          <div className="cards-icon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="101"
              height="101"
              viewBox="0 0 101 101"
            >
              <rect
                fill="rgba(51,51,51, 0.2)"
                className="svg-fill"
                width="100"
                height="100"
                rx="10"
                transform="translate(0.5 0.5)"
                stroke="#fff"
                stroke-miterlimit="10"
                stroke-width="1"
              />
              <g
                id="组_25"
                data-name="组 25"
                transform="translate(-1094.148 -1930.237)"
              >
                <path
                  id="路径_47"
                  data-name="路径 47"
                  d="M1163.574,2005.942a1.88,1.88,0,0,1-1.318-.543l-5.34-5.34-5.146,5.147a1.861,1.861,0,0,1-2.631,0l-5.143-5.147-5.247,5.247a1.861,1.861,0,0,1-2.631,0l-5.25-5.247-5.292,5.288a1.832,1.832,0,0,1-1.887.454,5.589,5.589,0,0,1-3.9-5.333v-43.925a5.618,5.618,0,0,1,5.615-5.616h37.186a5.626,5.626,0,0,1,5.619,5.616v43.929a5.626,5.626,0,0,1-4.141,5.4,1.889,1.889,0,0,1-.495.067Zm-6.654-10.375a1.871,1.871,0,0,1,1.318.544l5.689,5.69a1.876,1.876,0,0,0,.559-1.329v-43.929a1.9,1.9,0,0,0-1.9-1.894H1125.4a1.9,1.9,0,0,0-1.894,1.894v43.929a1.849,1.849,0,0,0,.458,1.221l5.582-5.582a1.86,1.86,0,0,1,2.631,0l5.25,5.247,5.247-5.247a1.86,1.86,0,0,1,2.631,0l5.147,5.146,5.146-5.146a1.869,1.869,0,0,1,1.321-.544Z"
                  fill="#fff"
                />
                <path
                  id="路径_48"
                  data-name="路径 48"
                  d="M1155.365,1967.907H1132.3a1.86,1.86,0,1,1,0-3.721h23.057a1.86,1.86,0,0,1,0,3.721Zm0,8.9H1132.3a1.861,1.861,0,0,1,0-3.722h23.057a1.861,1.861,0,0,1,0,3.722Zm0,8.9H1132.3a1.861,1.861,0,1,1,0-3.721h23.057a1.861,1.861,0,0,1,0,3.721Z"
                  fill="#fff"
                />
              </g>
            </svg>
          </div>
          <div className="cards-title">企业需求<div className="cards-title-en">Tech-Requirements</div></div>
          <div className="cards-desc">
            发布企业技术需求，架起专利供需对接的有效桥梁
          </div>
        </div>

        <div className="cards__item">
          <div className="cards-icon">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="101"
              height="101"
              viewBox="0 0 101 101"
            >
              <rect
                fill="rgba(51,51,51, 0.2)"
                className="svg-fill"
                width="100"
                height="100"
                rx="10"
                transform="translate(0.5 0.5)"
                stroke="#fff"
                stroke-miterlimit="10"
                stroke-width="1"
              />
              <g
                xmlns="http://www.w3.org/2000/svg"
                id="组_28"
                data-name="组 28"
                transform="translate(-1460.526 -1928.721)"
              >
                <path
                  id="路径_49"
                  data-name="路径 49"
                  d="M1512.8,1961.9l2.574,5.21a.873.873,0,0,0,.671.484l5.74.842a.889.889,0,0,1,.5,1.56l-4.149,4.056a.887.887,0,0,0-.265.8l.983,5.709a.89.89,0,0,1-1.3.952l-5.132-2.7a.891.891,0,0,0-.842,0l-5.132,2.7a.889.889,0,0,1-1.3-.952l.983-5.709a.89.89,0,0,0-.265-.8l-4.149-4.056a.889.889,0,0,1,.5-1.56l5.74-.842a.876.876,0,0,0,.671-.484l2.573-5.21a.889.889,0,0,1,1.591,0Z"
                  fill="#fff"
                />
                <g id="组_27" data-name="组 27">
                  <path
                    id="路径_50"
                    data-name="路径 50"
                    d="M1533.838,1948.721h-43.676a1.638,1.638,0,0,0-1.636,1.637v56.154a1.638,1.638,0,0,0,1.636,1.637h43.676a1.639,1.639,0,0,0,1.636-1.637v-56.154A1.638,1.638,0,0,0,1533.838,1948.721Zm-1.637,56.154h-40.4v-52.881h40.4Z"
                    fill="#fff"
                  />
                  <path
                    id="路径_51"
                    data-name="路径 51"
                    d="M1502.641,1994.11h18.718c1.254,0,1.637-.382,1.637-1.637s-.383-1.636-1.637-1.636h-18.718c-1.254,0-1.637.382-1.637,1.636S1501.387,1994.11,1502.641,1994.11Z"
                    fill="#fff"
                  />
                </g>
              </g>
            </svg>
          </div>
          <div className="cards-title">活动对接<div className="cards-title-en">Tech-Match</div></div>
          <div className="cards-desc">
            多元化专利技术推广活动形式，凸显带动作用和集聚效应
          </div>
        </div>
      </div>
    </div>
  );
};

export default Service;
