import BaseService from "../core/base-service";
import StorageService, { StorageItems } from "../core/storage-service";

export default class PatentnewsService extends BaseService {
  // TODO: change this to your route in server
  static route = "patentnews";
  private storage: StorageService = new StorageService();

  /*专利列表接口*/
  async getPatentnewsData(pageNo = 1, title?: string, area?: string) {
    const url = `/api/news/patent`;
    const formData = {
      page: pageNo,
      size: 3,
    };

    const response = await this.httpService.post<any, any>(url, formData);
    return response;
  }

  /*获取上海市地区接口 */
  async getDiscrict() {
    const url = `/api/third/patent/getDistrict`;
    const response = await this.httpService.get(url);
    return response;
  }

  /*获取机构数据 */
  async getOrgan() {
    const url = `/api/third/patent/getOrgan`;
    const response = await this.httpService.get(url);
    return response;
  }

  /* 获取产业数据 */
  async getIndustry() {
    const url = `/api/third/patent/getIndustry`;
    const response = await this.httpService.get(url);
    return response;
  }
  /* 获取法律状态 */
  async getLaw() {
    const url = `/api/third/patent/getLaw`;
    const response = await this.httpService.get(url);
    return response;
  }
}