/* eslint-disable */
/* tslint:disable */

import React from "react";
import banner from "../../images/banner.png";
import countIcon from "../../images/icon/home-count.svg";
import CountUp from "react-countup";

const Banner = () => {
  return (
    <div className="home-div-bannerContainer">
      <div className="banner-div-enName">SHANGHAI IP OPERATION PROMOTOR</div>
      <div className="banner-div-zhName">上海市专利技术推广运用平台</div>
      <img src={banner} />
      <div className="banner-div-dashboard">
        <div className="dashboard-div-card data-count">
          <div className="dataValue js-box">
            <CountUp start={0} end={177} />
            <span className="subUnit">个</span>
          </div>
          <div className="subTitle">
            <img src={countIcon} />
            专利交易数量
          </div>
        </div>

        <div className="dashboard-div-card data-amount">
          <div className="dataValue">
            <CountUp start={0} end={48400} />
            <span className="subUnit">万元</span>
          </div>
          <div className="subTitle">
            <img src={countIcon} />
            专利交易金额
          </div>
        </div>

        <div className="dashboard-div-card split-line"></div>

        <div className="dashboard-div-card data-rate">
          <div className="dataValue">
            <CountUp start={0} end={18} />
            <span className="subUnit">家</span>
          </div>
          <div className="subTitle">
            <img src={countIcon} />
            知产质押融资服务企业
          </div>
        </div>

        <div className="dashboard-div-card data-incr">
          <div className="dataValue">
            <CountUp start={0} end={55300} />
            <span className="subUnit">万元</span>
          </div>
          <div className="subTitle">
            <img src={countIcon} />
            知产质押融资金额
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner;
