/* eslint-disable */
/* tslint:disable */

import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

import area from "../../../images/area.png";

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const HexagonBlock: React.FC = () => {
  const areaName = useQuery().get("name");

  const list = [
    { left: "51px", top: "26px", title: "高薪企业数", value: 355 },
    { left: "155px", top: "26px", title: "科研院所数", value: 34 },
    { left: "1px", top: "109px", title: "金融机构数", value: 77 },
    { left: "104px", top: "109px", title: "区", value: 77 },
    { left: "208px", top: "109px", title: "服务机构数", value: 68 },
    { left: "52px", top: "192px", title: "高薪企业数", value: 355 },
    { left: "156px", top: "192px", title: "科研院所数", value: 234 },
  ];
  return (
    <div className="hexagonContainer">
      {list.map((item, index) => {
        return (
          <div
            key={index}
            className="hexagon"
            style={{ left: item.left, top: item.top }}
          >
            {index === 3 ? (
              <>
                <img className="hexagon-icon" src={area} />
                <div className="hexagon-title" style={{ fontSize: "16px" }}>
                  {areaName + item.title}
                </div>
              </>
            ) : (
              <>
                <div className="hexagon-data">{item.value}</div>
                <div className="hexagon-title">{item.title}</div>
              </>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default HexagonBlock;
