/* eslint-disable */
/* tslint:disable */

import banner from "../../images/banner2.png";
import searchIcon from "../../images/icon/search.png";
import viewIcon from "../../images/icon/view.png";
import shareIcon from "../../images/icon/share.png";
import collIcon from "../../images/icon/coll.png";
import React, { useEffect, useState } from "react";
import { getEnv } from "../../helpers/mobx-easy-wrapper";
import { Radio, Rate, Pagination,Select, message } from "antd";
import { useLocation } from "react-router-dom";

const newsList = [{}, {}, {}];

interface PatentItem {
  applyAddress: string;
  applyArea: string;
  applyCity: string;
  applyCityCode: string;
  applyDate: string;
  applyNum: string;
  applyer: string;
  createdAt: string;
  id: number;
  ipc: string;
  modifiedAt: string;
  openDate: string;
  openNum: string;
  patentType: string;
  summary: string;
  title: string;
  version: string;
  readTimes: Number;
  followerNumber: Number;
  shareTimes: Number;


  "UNIVERSITYMSG_ID": string,
  "PATENT_APPLICATION_NUMBER": string,
  "PATENT_TITLE": string,
  "PATENT_APPLICANT": string,
  "PATENT_ID": string,
  "PATENT_APPLICATION_DAY": string,
  "UNIVERSITYMSG_NAME": string,
  "PATENT_COMPREHENSIVE": number,
  "READ_TIMES": number,
  "SHARE_TIMES": number,
  "FOLLOWER_NUMBER": number,
}

/**
 * applyAddress: "200433 上海市杨浦区邯郸路220号"
 applyArea: "[杨浦]"
 applyCity: "[上海]"
 applyCityCode: "上海; 31"
 applyDate: "2010-12-24"
 applyNum: "CN201010604436.3"
 applyer: "复旦大学"
 createdAt: "2022-03-27T01:05:19.406+0000"
 id: 512
 ipc: "G01D5/26; H04B10/08; H04B10/077"
 modifiedAt: null
 openDate: "2012-07-25"
 openNum: "CN102087356B"
 patentType: "发明授权"
 summary: "本发明属于分布式测量技术领域，具体为一种对两个具有固定时延差的叠加复合信号的处理方法。本发明具体涉及内部由两个具有延迟子信号叠加构成的一对复合信号所含信息的提取，这两个复合信号中子信号的延迟具有固定差值。该方法通过时域上的变换获得信号源的幅频特性，不仅可用于对扰动源特性的分析，也可以为信号频域的分析提供参考基准，消除信号源特性变化对信号处理的影响。本发明还可获得两个具有很强相关性的信号，采用时间延迟估计算法得到延迟信息，避免了将时域信号转化到频域内分析的复杂性，使数据处理更加方便。本发明特别适用于光纤分布式定位监测系统。"
 title: "对两个具有固定时延差的叠加复合信号的处理方法"
 version: null
 */
interface PatentResponse {
  items: Array<PatentItem>;
  totalItems: number;
  totalPages: number;
}

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const Patent = () => {
  const urlSearch = useQuery().get("search");
  const [pageNo, setPageNo] = useState(1);
  const [ready, setReady] = useState(0);
  const [data, setData] = useState<Array<PatentItem>>();
  const [totalNums, setTotalNums] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [search, setSearch] = useState(urlSearch ? urlSearch : "");
  const [inputSearch, setInputSearch] = useState(search);

  const [area, setArea] = useState();
  const [org, setOrg] = useState();
  const [industry, setIndustry] = useState();
  const [law, setLaw] = useState();

  const [dialogItem, setDialogItem] = useState<PatentItem>();

  const [areaData, setAreaData] = useState<Array<{DISTRICE_NAME: string}>>();
  const [lawData, setLawData] = useState<Array<{LAW_NAME: string}>>();
  const [organData, setOrganData] = useState<Array<{ORGAN_ID: string, ORGAN_NAME: string}>>();
  const [industryData, setIndustryData] = useState<Array<{INDUSTRY_NAME: string, INDUSTRY_CODE: string}>>();
  
  const [logined, setLogined] = useState(false);
  const [user, setUser] = useState();

  const { apiFactory } = getEnv();
  const getData = async () => {
    //async function fetchData() {
    const response = await apiFactory.techService.getTechData(
        pageNo,
        search,
        area,
        law,
        industry,
        org
    );

    const totalPages = response.count % response.limit === 0 ? response.count / response.limit : Math.floor(response.count / response.limit) + 1;

    setTotalNums(response.count);
    setTotalPages(totalPages);
    setData(response.data);
    // }
    //fetchData();
  };
  const fetchDict = async () => {
    const discrictData = await apiFactory.patentService.getDiscrict();
    const organData = await apiFactory.patentService.getOrgan();
    const industryData = await apiFactory.patentService.getIndustry();
    const lawData = await apiFactory.patentService.getLaw();

    /*console.log("第script", discrictData)*/
    setLawData((lawData as any).data);
    setOrganData((organData as any).data);
    setAreaData((discrictData as any).data);
    setIndustryData((industryData as any).data);
    setReady(1);
  }
  
  const showDetail = (item: any) => {
	  console.log(logined, user)
	  if (logined == false || user == null || user == undefined) {
		  message.error("用户未登录，请先登录");
		  return;
	  }
	  location.href = `/patent/detail?pid=${item.PATENT_ID}&uid=${item.UNIVERSITYMSG_ID}`
  }

  // empty array as second argument equivalent to componentDidMount
  useEffect(()=>{
	const loginFlag = apiFactory.authService.isAuthenticated();
	if (loginFlag) {
	  const user = apiFactory.authService.getLoginUser();
	  setUser(user);
	}
	setLogined(loginFlag);
    fetchDict();
  }, [])
  useEffect(() => {
    if(ready === 0) {
      return;
    }
    console.log('from page')
    getData();
  }, [pageNo]);
  useEffect(() => {
    if(ready === 0) {
      return;
    }
    console.log('from search')
    if (pageNo != 1) {
      setPageNo(1);
    } else {
      getData();
    }
  }, [ready, search, area, law, industry, org]);
  /*  useEffect(() => {

      setSearch(inputSearch)
  }, [ inputSearch]);*/

  return (
      <div className="home">
        <div className="home-div-bannerContainer">
          <div>
            <img src={banner} />
          </div>
        </div>

        {/*搜索框*/}
        <div className="search--input">
          <div className="container">
            <div className="container__addon prefix__icon">
              <img src={searchIcon} />
            </div>

            <input
                type="text"
                className="container__input"
                value={inputSearch}
                onChange={(e) => {
                  setInputSearch(e.target.value);
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    setSearch(inputSearch);
                    /*  const searchWord = (e.target as HTMLInputElement).value;
                    searchWord ? location.href = `/patent?search=${searchWord}`: void(0);*/
                  }
                }}
                placeholder="可以输入多个关键词，使用逗号或空格隔开"
            />

            <div
                className="container__addon search__label"
                onClick={() => {
                  setSearch(inputSearch);
                }}
            >
              搜索
            </div>
          </div>
        </div>

        {/*区域*/}
        <div className="patent__condition">
          <label>区域：</label>
          <Select
              style={{width: 120}}
              value={area}
              defaultValue={null}
              onChange={(value: any) => {
                setArea(value);
              }}
          >
            <Select.Option value={null}>全部</Select.Option>
            {areaData?.map(dist  => {
              return (<Select.Option value={dist.DISTRICE_NAME}>{dist.DISTRICE_NAME}</Select.Option>)
            })
            }
          </Select>

          <label style={{marginLeft: "40px"}}>产业：</label>
          <Select
              style={{width: 180}}
              value={industry}
              defaultValue={null}
              onChange={(value: any) => {
                setIndustry(value);
              }}
          >
            <Select.Option value={null}>全部</Select.Option>
            {industryData?.map(org  => {
              return (<Select.Option value={org.INDUSTRY_CODE}>{org.INDUSTRY_NAME}</Select.Option>)
            })
            }
          </Select>
          <label style={{marginLeft: "40px"}}>机构：</label>
          <Select
              style={{width: 180}}
              defaultValue={null}
              value={org}
              onChange={(value: any) => {
                setOrg(value);
              }}
          >
            <Select.Option value={null}>全部</Select.Option>
            {organData?.map(org  => {
              return (<Select.Option value={org.ORGAN_ID}>{org.ORGAN_NAME}</Select.Option>)
            })
            }
          </Select>
          <label style={{marginLeft: "40px"}}>法律状态：</label>
          <Select
              style={{width: 120}}
              value={law}
              defaultValue={null}
              onChange={(value: any) => {
                setLaw(value);
              }}
          >
            <Select.Option value={null}>全部</Select.Option>
            {lawData?.map(org  => {
              return (<Select.Option value={org.LAW_NAME}>{org.LAW_NAME}</Select.Option>)
            })
            }
          </Select>

        </div>
        {/*
      <div className="jury__line outlineTitle" style={{ marginLeft: "0px" }}>
        <label>新闻动态</label>
      </div>*/}

        <ul className="patent-div-container">
          {data?.map((item, index) => {
            return (
                <li
                    key={index}
                    className="patent-item"
                    onClick={() => {
                      setDialogItem(item);
                    }}
                >
                  <div className="patent-div-header">
                <span className="metaInfo">
                  {item.PATENT_APPLICANT} / {item.PATENT_APPLICATION_NUMBER} / {item.PATENT_APPLICATION_DAY}
                </span>
                    <div className="rateContainer">
                      <Rate disabled defaultValue={item.PATENT_COMPREHENSIVE} />
                    </div>
                  </div>
                  <div className="patent-content">
                    <div className="notice-div-content">
                      <div
                          className="notice-date"
                          onClick={() => {
							  showDetail(item);
						  }}
                      >
                        {item.PATENT_TITLE}
                      </div>
                      <div
                          className="notice-summary"
                          onClick={() => {
							  showDetail(item)
							}}
                      >
                        {item.PATENT_TITLE}
                      </div>
                    </div>

                    <div className="notice-div-img">
                      <div className="patentIcon">
                        <div>
                          <img src={viewIcon} />
                        </div>
                        <label>{item.READ_TIMES}</label>
                      </div>
                      <div className="patentIcon">
                        <div>
                          <img src={collIcon} />
                        </div>
                        <label>{item.FOLLOWER_NUMBER}</label>
                      </div>
                      <div className="patentIcon">
                        <div>
                          <img src={shareIcon} />
                        </div>
                        <label>{item.SHARE_TIMES}</label>
                      </div>
                    </div>
                  </div>
                </li>
            );
          })}
        </ul>

        <div className="pagination">
          {totalPages > 1 && (
              <Pagination
                  current={pageNo}
                  onChange={setPageNo}
                  total={totalNums}
                  showSizeChanger={false}
              />
          )}
          {/*   <div
          className="pagination__item"
          style={{ width: "72px" }}
          onClick={prevPage}
        >
          {" "}
          <div className="leftArrow"></div>上一页
        </div>
        {[...Array(totalPage)].map((item, i) => {
          return (
            <div
              key={i + "page"}
              className={`${
                pageNo == i + 1 ? "pagination__item active" : "pagination__item"
              }`}
              onClick={() => {
                setPageNo(i + 1);
              }}
            >
              {i + 1}
            </div>
          );
        })}
        <div
          className="pagination__item"
          style={{ width: "72px" }}
          onClick={nextPage}
        >
          下一页<div className="rightArrow"></div>
        </div>*/}
        </div>
      </div>
  );
};

export default Patent;
