import React from "react";

export interface OuterMeterProps {
  max: number;
}

const OuterMeter = (props: OuterMeterProps) => {
  return (
    <div className="large-circular">
      <div className="circular">
        <div className="inner"></div>
        <div className="circle">
          <div className="bar left">
            <div className="progress"></div>
          </div>
          {/*  <div className="bar right">
                                  <div className="progress"></div>
                              </div>*/}
        </div>
      </div>
      <span className="meter-label left">0</span>
      <span className="meter-label right">{props.max}</span>
    </div>
  );
};

export default OuterMeter;
