export const formatNum = (num: number) => {
  // 将数字串转换成带逗号的显示方式
  if (!/^([+\-])?(\d+)(\.\d+)?$/.test(num + "")) {
    alert("wrong!");
    return num;
  }
  let a = RegExp.$1;
  let b = RegExp.$2;
  let c = RegExp.$3;
  // @ts-ignore
  let re = new RegExp().compile("(\\d)(\\d{3})(,|$)");
  while (re.test(b)) b = b.replace(re, "$1,$2$3");
  return a + "" + b + "" + c;
};
