/* eslint-disable */
/* tslint:disable */

import React, {useEffect, useState} from "react";

import SubTitle from "./title/sub-title";
import banner from "../../images/banner2.png";
import lovePng from "../../images/icon/love_w.png";
import lovedPng from "../../images/icon/loved_w.png";
import sharePng from "../../images/icon/share_w.png";
import viewPng from "../../images/icon/view_w.png";
import JuryCard from "./jury-card/jury-card";
import moment from "moment";
import {getEnv} from "../../helpers/mobx-easy-wrapper";
import Dialog from "../../components/dialog/dialog";
import {Button, Col, Input, Pagination, Row} from "antd";
import { PlusOutlined } from '@ant-design/icons';


interface NewsItem {
    activityEnd: string;
    activityStart: string;
    address: string;
    city: string;
    country: string;
    district: string
    followerNumber: number
    hideRegister: boolean
    id: number
    latitude: number
    longitude: number
    province: string;
    registerEnd: string;
    registerStart: string
    state: string;
    thumbnail: string;
    title: string;
    type: "offline"|"online"
}

interface DetailItem {
    achievement: string
    activityEnd: string
    activityStart: string
    demand: string
    description: string
    focus: boolean
    followerNumber: number
    hideRegister: boolean
    id: number
    liveVideo: string
    registerEnd: string
    registerStart: string
    registered: false
    state: "activity_ongoing"
    thumbnail: string
    title: string
}

const ActivityNews = () => {
  const [data, setData] = useState<NewsItem[]>([]);
  const [pageNo, setPageNo] = useState(1);
    const [totalNums, setTotalNums] = useState(1);
    const [totalPages, setTotalPages] = useState(1);

    const [dialogItem, setDialogItem] = useState<DetailItem>();
    const [showDialog, setShowDialog] = useState(false);
    const [showEnter, setShowEnter] = useState(false);

    const [type, setType] = useState('online');

    const { apiFactory } = getEnv();
    const getData = () => {

        async function fetchData() {
            let response;
            if(type === 'online') {
                response = await apiFactory.techService.getOnlineActivity(
                    pageNo
                );
            } else {
                response = await apiFactory.techService.getOfflineActivity(
                    pageNo
                );
            }


            const totalPages = response.totalPages;


            setTotalNums(response.totalItems);
            setTotalPages(totalPages);
            setData(response.items);
        }
        fetchData();
    };

    const getDetail = async (id:string) => {
        const response = await apiFactory.techService.getActivityDetail(id, type);
        setDialogItem(response);
        setShowDialog(true);
    }

    const collectActivity = async (id: string) => {
        const response = await apiFactory.techService.focusActivity(id);
        console.log(response)
    }


    useEffect(() => {
        getData();
    }, [pageNo,type]);
    useEffect(()=>{
        if(showDialog === false) {
            setShowEnter(false);
        }
    }, [showDialog])

  return (
    <div className="home" style={{}}>


        {showDialog && (
            <Dialog
                onClose={() => {
                    setShowDialog(false);
                }}
            >
                <div className="fullDialog">
                    <div
                        className="closeIcon"
                        onClick={() => {
                            setShowDialog(false);
                        }}
                    ></div>
                    <div className="dialogTitle">{dialogItem?.title}</div>
                    <div className="dialogTime">
                        {" "}
                      {/*  {moment(dialogItem?.publishAt).format("YYYY年MM月DD日")}*/}
                    </div>
                    <div
                        className="dialogContent"
                        dangerouslySetInnerHTML={{ __html: dialogItem!.description }}
                    ></div>
                    <div>
                        <div className="dialogImg">
                            <img src={dialogItem?.thumbnail} />
                        </div>
                        {showEnter && <div className="buttonArea" style={{textAlign: "left"}}>
                            <Input.Group >
                                <Row gutter={12}>
                                    <Col span={8}>
                                        <Input placeholder="姓名" />
                                    </Col>
                                    <Col span={8}>
                                        <Input placeholder="电话" />
                                    </Col>
                                    <Col span={8}>
                                        <Input placeholder="邮箱" />
                                    </Col>
                                </Row>
                            </Input.Group>
                            <Input.Group style={{marginTop: "20px"}}>
                                <Row gutter={12}>
                                    <Col span={16}>
                                        <Input placeholder="公司" />
                                    </Col>
                                    <Col span={7}>
                                        <Input placeholder="职位" />
                                    </Col>
                                    <Col span={1}>
                                        <Button type="primary" icon={<PlusOutlined />}    />
                                    </Col>
                                </Row>
                            </Input.Group>
                        </div>}
                        <div className="buttonArea">
                            {!showEnter && <button
                                className="primaryButton"
                                onClick={() => {
                                    setShowEnter(true)
                                }}
                            >
                                我要报名
                            </button>}
                        </div>
                        <div className="buttonArea">
                            {!showEnter && <button
                                className="primaryButton"
                                onClick={() => {

                                }}
                            >
                                追踪该发布人
                            </button>}
                            {showEnter && <button
                                className="primaryButton"
                                onClick={() => {

                                }}
                            >
                                确认报名
                            </button>}
                            <button
                                className="closeButton"
                                style={{marginLeft: "35px"}}
                                onClick={() => {
                                    setShowDialog(false);
                                }}
                            >
                                关闭
                            </button>
                        </div>
                    </div>
                </div>
            </Dialog>
        )}

        <div className="home-div-bannerContainer">
            <div>
                <img src={banner} />
            </div>
        </div>

    <div className="jury__line tablineTitle" style={{maxWidth: "1400px" , marginBottom: "35px"}}>
        <label className={type === 'online' ? 'active': ''} onClick={()=>{ setType('online')}}>线上活动</label>
        <label className={type === 'offline' ? 'active': ''}  style={{marginLeft: "45px"}}  onClick={()=>{ setType('offline')}} >线下活动</label>

    </div>
    <div style={{display: 'flex', flexWrap: 'wrap', justifyContent: 'space-between', gap: '30px', marginBottom: '50px'}}>
        {
            data?.map((item, index) => {
                return(
                    <div className="an-card">
                        <div className="an-card__cover">
                            <img src={item.thumbnail} />
                            <div className="an-icon"  style={{right: "130px"}}><img src={viewPng}/></div>
                            <div className="an-icon"  style={{right: "105px"}}>0</div>
                            <div className="an-icon"  style={{right: "70px"}} onClick={()=>{ collectActivity(item.id+'')}} ><img src={lovePng}/></div>
                            <div className="an-icon"  style={{right: "50px"}}>{item.followerNumber}</div>
                            <div className="an-icon"  style={{right: "15px", top:"-2px"}}><img src={sharePng}/></div>
                        </div>
                        <div className="an-card__content">
                            <div className="an-card__title">{item.title}</div>
                            <div className="an-card__date">{moment(item.activityStart).format('YYYY.MM.DD')} - {moment(item.activityEnd).format('YYYY.MM.DD')}</div>
                            {item.type === 'offline' &&  <div className="an-card__area">{item.city} {item.district}</div>}
                            <div className="solidButton enterButton" onClick={ async ()=>{ await getDetail(item.id +'') }}>报名参加</div>
                        </div>
                    </div>
                )
            })
        }

    </div>

        <div className="pagination">
            {totalPages > 1 && (
                <Pagination
                    current={pageNo}
                    onChange={setPageNo}
                    total={totalNums}
                    showSizeChanger={false}
                />
            )}
        </div>
    </div>
  );
};

export default ActivityNews;
