/* eslint-disable */
/* tslint:disable */

import React, { useEffect, useState } from "react";
import { Layout, message } from "antd";
import { observer } from "mobx-react";
import { Menu, Dropdown } from "antd";
import logo from "../../images/icon/cloud.svg";
import search from "../../images/icon/search.svg";
import dataLogo from "../../images/data-logo.png";
import headerLogo from "../../images/headerLogo.png";
import footerLogo from "../../images/footerLogo.png";
import userLogin from "../../images/user.png";
import Dialog from "../dialog/dialog";
import Login from "../../features/user/login/login";
import { getEnv } from "../../helpers/mobx-easy-wrapper";

const Header = () => {
  const [logined, setLogined] = useState(false);
  const [user, setUser] = useState();
  const [showLogin, setShowLogin] = useState(false);
  const { apiFactory } = getEnv();
  useEffect(() => {
    const loginFlag = apiFactory.authService.isAuthenticated();
    if (loginFlag) {
      const user = apiFactory.authService.getLoginUser();
      setUser(user);
    }
    setLogined(loginFlag);
  }, []);

  const logout = () => {
    apiFactory.authService.logout();
    message.success("注销成功");
    setTimeout(() => {
      window.location.reload();
    }, 600);
  };

  // @ts-ignore
  const menu = (
    <Menu>
      <Menu.Item key="1">
        <label>注销</label>
      </Menu.Item>
      {/*        <Menu.SubMenu key="SubMenu" title="Navigation Three - Submenu" icon={<SettingOutlined />}>
          <Menu.ItemGroup title="Item 1">
            <Menu.Item key="app" icon={<AppstoreOutlined />}>
              Navigation Two
            </Menu.Item>
            <Menu.Item key="disabled" disabled>
              Navigation Three
            </Menu.Item>
          </Menu.ItemGroup>
        </Menu.SubMenu>*/}
    </Menu>
  );

  return (
    <Layout.Header
      className="header"
      onMouseLeave={() => {
        (document.querySelector(".header") as HTMLDivElement).classList.remove(
          "leave"
        );
        (
          document.querySelector(".header") as HTMLDivElement
        ).style.removeProperty("transform");
      }}
    >
      <ul className="container">
        <li>
          {" "}
          <img
            className="logo"
            src={footerLogo}
            onClick={() => (location.href = "/")}
            style={{
              width: "300px",
              height: "auto",
              float: "left",
              marginRight: "35px",
            }}
          />{" "}
        </li>
        <li>
          <ul className="dropdown">
            <li onClick={() => (location.href = "/")}>首页</li>
            <li>
              <div>
                <img className="logo" src={dataLogo} />
                专利数据驾驶舱
              </div>
              <ul>
                <li onClick={() => (location.href = "/data#city")}>上海市</li>
				<li onClick={() => (location.href = "/data?name=黄浦#area")}>
				  黄浦区
				</li>
				<li onClick={() => (location.href = "/data?name=徐汇#area")}>
				  徐汇区
				</li>
				<li onClick={() => (location.href = "/data?name=长宁#area")}>
				  长宁区
				</li>
				<li onClick={() => (location.href = "/data?name=静安#area")}>
				  静安区
				</li>
                <li onClick={() => (location.href = "/data?name=普陀#area")}>
                  普陀区
                </li>
				<li onClick={() => (location.href = "/data?name=虹口#area")}>
				  虹口区
				</li>
				<li onClick={() => (location.href = "/data?name=杨浦#area")}>
				  杨浦区
				</li>
                <li onClick={() => (location.href = "/data?name=闵行#area")}>
                  闵行区
                </li>
                <li onClick={() => (location.href = "/data?name=宝山#area")}>
                  宝山区
                </li>
                <li onClick={() => (location.href = "/data?name=嘉定#area")}>
                  嘉定区
                </li>
				<li onClick={() => (location.href = "/data?name=浦东#area")}>
				  浦东区
				</li>
				<li onClick={() => (location.href = "/data?name=金山#area")}>
				  金山区
				</li>
				<li onClick={() => (location.href = "/data?name=松江#area")}>
				  松江区
				</li>
				<li onClick={() => (location.href = "/data?name=青浦#area")}>
				  青浦区
				</li>
                <li onClick={() => (location.href = "/data?name=奉贤#area")}>
                  奉贤区
                </li>
                <li onClick={() => (location.href = "/data?name=崇明#area")}>
                  崇明区
                </li>
              </ul>
            </li>
            <li onClick={() => (location.href = "/patent")}>
              <div>专利检索</div>
              {/*    <ul>
                <li>专利检索</li>
                <li>企业需求</li>
              </ul>*/}
            </li>
            <li onClick={() => (location.href = "/news")}>
                  <div>政策资讯</div>
                  {/*    <ul>
                <li>专利检索</li>
                <li>企业需求</li>
              </ul>*/}
            </li>
              <li onClick={() => (location.href = "/activity")}>
                  <div>活动</div>
              </li>
            {/*   <li>
              <div>平台服务</div>
              <ul>
                <li>科创金融</li>
                <li>技术交易</li>
                <li>对接活动</li>
                <li>政策资讯</li>
              </ul>
            </li>*/}
            {/*<li onClick={() => (location.href = "/match")}>高价值专利大赛</li>*/}

            {/*<li>关于我们</li>*/}
          </ul>
        </li>

        <li className="container__item--right">
          <div className="search__container">
            <input
              type="text"
              className="container__input"
              placeholder="搜索专利"
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  const searchWord = (e.target as HTMLInputElement).value;
                  searchWord
                    ? (location.href = `/patent?search=${searchWord}`)
                    : void 0;
                }
              }}
            />
            <div style={{ padding: "11px 12px 12px 12px" }}>
              <div
                style={{
                  height: "24px",
                  width: "24px",
                }}
              >
                <img src={search} />
              </div>
            </div>
          </div>
          <div
            id="loginHeader"
            className="user"
            onClick={() => {
              !logined && setShowLogin(true);
            }}
          >
            <div className={`${logined ? "dropdown" : ""}`}>
              <img src={userLogin} />
              {logined && (
                <>
                  <label>{user && (user! as any).name}</label>
                  <div className="dropdown__content">
                    <div
                      className="logout"
                      style={{ marginTop: "8px" }}
                      onClick={logout}
                    >
                      注销
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </li>
      </ul>

      {showLogin && (
        <Dialog>
          <Login
            closeEvent={() => {
              setShowLogin(false);
            }}
          />
        </Dialog>
      )}
    </Layout.Header>
  );
};

export default observer(Header);
