/* eslint-disable */
/* tslint:disable */

import React, { useEffect, useState } from "react";

const WeatherBlock: React.FC = () => {
  const [count, setCount] = useState(0);
  // const src = "//tianqi.2345.com/plugin/widget/index.htm?s=1&z=3&t=0&v=1&d=1&bd=0&k=&f=&ltf=009944&htf=cc0000&q=0&e=1&a=1&c=58362&w=164&h=100&align=center";
  useEffect(() => {
    // @ts-ignore
    window["WIDGET"] = {
      CONFIG: {
        layout: "1",
        width: "164",
        height: "100",
        background: "1",
        dataColor: "FFFFFF",
        borderRadius: "10",
        modules: "10",
        key: "62ac181d41374d3aa0b6bb34d611ea8a",
      },
    };
    let script = document.createElement("script");
    script.type = "text/javascript";
    script.src =
      "https://widget.qweather.net/standard/static/js/he-standard-common.js?v=2.0";
    document.getElementsByTagName("head")[0].appendChild(script);
  }, [count]);
  return (
    <div className="weather-div-container dashDashboard-block">
      <div id="he-plugin-standard"></div>
	  {/*<iframe frameBorder="0" width="164" height="100" scrolling="no" src={src}></iframe>*/}
    </div>
  );
};

export default WeatherBlock;
