/* eslint-disable */
/* tslint:disable */

import banner from "../../../images/banner.png";
import React, { useEffect, useState } from "react";
import SubTitle from "../title/sub-title";
import Dialog from "../../../components/dialog/dialog";
import { url } from "inspector";
import { getEnv } from "../../../helpers/mobx-easy-wrapper";
import { Pagination } from "antd";
import moment from "moment";

const newsList = [{}, {}, {}];

interface NoticeItem {
  id: string;
  title: string;
  author: string;
  content: string;
  creator: string;
  publishAt: string;
  summary: string;
  thumbnail: string;
  video: string;
}
interface NoticeResponse {
  items: Array<NoticeItem>;
  totalItems: number;
  totalPages: number;
}

const Notice = () => {
  const [showDialog, setShowDialog] = useState(false);
  const [pageNo, setPageNo] = useState(1);
  const [data, setData] = useState<Array<NoticeItem>>();
  const [totalPage, setTotalPage] = useState(1);
  const [totalNums, setTotalNums] = useState(1);
  const [dialogItem, setDialogItem] = useState<NoticeItem>();

  const nextPage = () => {
    if (pageNo < totalPage) {
      setPageNo(pageNo + 1);
    }
  };

  const prevPage = () => {
    if (pageNo > 1) {
      setPageNo(pageNo - 1);
    }
  };

  // empty array as second argument equivalent to componentDidMount
  useEffect(() => {
    const { apiFactory } = getEnv();
    async function fetchData() {
      const response = await apiFactory.activityService.getNoticeData(pageNo);
      setTotalPage(response.totalPages);
      setTotalNums(response.setTotalNums);
      setData(response.items);
    }
    fetchData();
  }, [pageNo]);

  return (
    <div className="home">
      <div className="jury__line outlineTitle" style={{ marginLeft: "0px" }}>
        <label>新闻动态</label>
      </div>

      {showDialog && (
        <Dialog
          onClose={() => {
            setShowDialog(false);
          }}
        >
          <div className="fullDialog">
            <div
              className="closeIcon"
              onClick={() => {
                setShowDialog(false);
              }}
            ></div>
            <div className="dialogTitle">{dialogItem?.title}</div>
            <div className="dialogTime">
              {" "}
              {moment(dialogItem?.publishAt).format("YYYY年MM月DD日")}
            </div>
            <div
              className="dialogContent"
              dangerouslySetInnerHTML={{ __html: dialogItem!.content }}
            ></div>
            <div>
              <div className="dialogImg">
                <img src={dialogItem?.thumbnail} />
              </div>
              <div className="buttonArea">
                <button
                  className="closeButton"
                  onClick={() => {
                    setShowDialog(false);
                  }}
                >
                  关闭
                </button>
              </div>
            </div>
          </div>
        </Dialog>
      )}

      <ul className="notice-div-container">
        {data?.map((item, index) => {
          return (
            <li
              key={index}
              onClick={() => {
                setDialogItem(item);
                setShowDialog(true);
              }}
            >
              <div className="notice-div-content">
                <div className="notice-date">
                  {moment(item.publishAt).format("YYYY年MM月DD日")}
                </div>
                <div className="notice-summary">
                  {(item as NoticeItem).summary}
                </div>
              </div>

              <div className="notice-div-img">
                <img src={(item as NoticeItem).thumbnail} />
              </div>
            </li>
          );
        })}
      </ul>

      <div className="pagination">
        {totalPage > 1 && (
          <Pagination
            current={pageNo}
            onChange={setPageNo}
            total={totalNums}
            showSizeChanger={false}
          />
        )}
        {/*  <div
          className="pagination__item"
          style={{ width: "72px" }}
          onClick={prevPage}
        >
          {" "}
          <div className="leftArrow"></div>上一页
        </div>
        {[...Array(totalPage)].map((item, i) => {
          return (
            <div
              key={i + "page"}
              className={`${
                pageNo == i + 1 ? "pagination__item active" : "pagination__item"
              }`}
              onClick={() => {
                setPageNo(i + 1);
              }}
            >
              {i + 1}
            </div>
          );
        })}
                <div className="pagination__item">1</div>
        <div className="pagination__item">2</div>
        <div className="pagination__item">3</div>
        <div className="pagination__item">4</div>
        <div className="pagination__item">5</div>
        <div
          className="pagination__item"
          style={{ width: "72px" }}
          onClick={nextPage}
        >
          下一页<div className="rightArrow"></div>
        </div>*/}
      </div>
    </div>
  );
};

export default Notice;
