import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import DataDashboard from "../data-cockpit/data-board";
import Home from "../home/home";
import Activity from "../activity/activity";
import Jury from "../activity/jury";
import Notice from "../activity/notice/notice";
import ActivityDetail from "../activity/detail";
import Login from "../user/login/login";
import Patent from "../patent/patent";
import News from "../patent/news";
import PatentDetail from "../patent/detail";
import ActivityNews from "../activity/activity-news";
import YPDataDashboard from "../data-cockpit/yp-data-board";

const MainRouter = () => {
  return (
    <Router>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/login" component={Login} />
        <Route exact path="/home" component={Home} />
        <Route path="/match" component={Activity} />
        {/*   <Route exact path="/detail" component={ActivityDetail} />
        <Route exact path="/jury" component={Jury} />*/}
        <Route exact path="/notice" component={Notice} />
        <Route exact path="/data" component={DataDashboard} />
        <Route exact path="/yangpu" component={YPDataDashboard} />
        <Route exact path="/patent" component={Patent} />
        <Route exact path="/news" component={News} />
        <Route exact path="/activity" component={ActivityNews} />*
        <Route exact path="/patent/detail" component={PatentDetail} />

        <Redirect to="/" />
      </Switch>
    </Router>
  );
};

export default MainRouter;
