/* eslint-disable */
/* tslint:disable */

import React from "react";

export interface CardProps {
  icon: string;
  title: string;
  value: number;
}

const Card: React.FC<CardProps> = (props) => {
  return (
    <div className="card-div-container">
      <span className="card-span-title">{props.title}</span>
      <img src={props.icon} className="card-img-icon" />
      <div className="card-div-data">{props.value}</div>
    </div>
  );
};

export default Card;
