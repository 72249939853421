import React from "react";

const DashboardTitle = () => {
  return (
    <div className="dataDashboard-div-titleContainer">
      <div className="titleContainer-div-separator"></div>
      <div className="titleContainer-div-content">
        <a href="/">上海市专利技术推广运用平台 SIPOP</a>
      </div>
      <div className="titleContainer-div-separator"></div>
    </div>
  );
};

export default DashboardTitle;
