import React, { useEffect, useState } from "react";
import {
  Alert,
  Form,
  Input,
  Checkbox,
  Button,
  Radio,
  Upload,
  Row,
  Col,
  message,
  Space,
  notification,
} from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import { getEnv } from "../../../helpers/mobx-easy-wrapper";

const College = () => {
  const [unitNames, setUnitNames] = useState([{}]);
  const [form] = Form.useForm();
  const [detail, setDetail] = useState({});
  const [errorMsg, setErrorMsg] = useState("");
  const [countdown, setCountdown] = useState("");

  const { apiFactory } = getEnv();

  const onFinish = (values: any) => {
    console.log("Received values of form: ", values);
  };
  const add = () => {
    formData.unitName.push({});
    setUnitNames([...unitNames, {}]);
  };
  const remove = (index: number) => {};

  const formData = {
    unitName: [{}],
  };
  function showTip() {
    notification.info({
      message: `报名未开始 `,
      description: countdown,
      placement: "top",
    });
  }

  function countTime() {
    //获取当前时间
    var date = new Date();
    var now = date.getTime();
    //设置截止时间
    var str = "2022/5/10 14:30:00";
    var endDate = new Date(str);
    var end = endDate.getTime();

    //时间差
    var leftTime = end - now;
    //定义变量 d,h,m,s保存倒计时的时间
    var d, h, m, s;
    if (leftTime >= 0) {
      d = Math.floor(leftTime / 1000 / 60 / 60 / 24);
      h = Math.floor((leftTime / 1000 / 60 / 60) % 24);
      m = Math.floor((leftTime / 1000 / 60) % 60);
      s = Math.floor((leftTime / 1000) % 60);
    }
    //将0-9的数字前面加上0，例1变为01
    d = checkTime(d);
    h = checkTime(h);
    m = checkTime(m);
    s = checkTime(s);
    function checkTime(i: any) {
      if (i < 10) {
        i = "0" + i;
      }
      return i;
    }
    // console.log()
    setCountdown(`距离报名时间还有${d}天${h}时${m}分`);
    //递归每秒调用countTime方法，显示动态时间效果
    setTimeout(countTime, 1000);
  }

  useEffect(() => {
    async function getDetail() {
      const result = await apiFactory.activityService.getWorksDetail();
      if (result.status == 401) {
        message.error("用户未登录，请先登录");
        (document.getElementById("loginHeader") as HTMLDivElement).click();
        return;
      }
      if (result) {
        result.orgName && (result.orgName = result.orgName.split(","));
        if (result.patentNo) {
          const patentsArray = [] as any;
          result.patentNo.split(",").forEach((item: string, index: number) => {
            const pName = result.patentName
              ? result.patentName.split(",")[index]
              : "";
            const pUser = result.patentUsers
              ? result.patentUsers.split(",")[index]
              : "";
            patentsArray.push({
              no: item,
              name: pName,
              users: pUser,
            });
          });
          result.patents = patentsArray;
        }

        form.setFieldsValue(result);
        if (result.status === 1) {
          setErrorMsg(result.failReason);
        }
        setDetail(result);
      }
      console.log(result);
    }
    getDetail();
    countTime();
  }, []);

  const submitForm = async () => {
    //form.submit();
    if (!apiFactory.authService.isAuthenticated()) {
      message.error("用户未登录，请先登录");
      return;
    }
    const values = await form.validateFields();
    console.log("Success:", values);
    const formData = form.getFieldsValue();

    //formData.orgName = formData.orgName.join(",");
    const patentNoArray = [];
    const patentNameArray = [];
    const patentUsersArray = [];

    for (const patent of formData.patents) {
      patentNoArray.push(patent.no);
      patentNameArray.push(patent.name);
      patentUsersArray.push(patent.users);
    }
    formData.type = "高校";
    formData.patentNo = patentNoArray.join(",");
    formData.patentName = patentNameArray.join(",");
    formData.patentUsers = patentUsersArray.join(",");
    formData.id = (detail as any).id;
    console.log("formData", formData);
    //const finalData = Object.assign(detail, formData)
    const res = await apiFactory.techService.postRegistrationForm(formData);
    if (res.status == 401) {
      message.error("用户未登录，请先登录");
      return;
    }
    message.success("提交成功");
  };

  return (
    <div className="home">
      {errorMsg && (
        <Alert
          style={{ position: "relative", top: "30px" }}
          message={errorMsg}
          type="error"
          showIcon
          closable
        />
      )}

      <div className="jury__line outlineTitle" style={{ marginLeft: "0px" }}>
        <label>高校院所组报名</label>
      </div>
      <div className="subTitle-zh" style={{ marginBottom: "48px" }}>
        基本情况
      </div>

      <Form
        name="complex-form"
        form={form}
        onFinish={onFinish}
        labelCol={{ span: 3 }}
        wrapperCol={{ span: 18 }}
        style={{ marginBottom: "68px" }}
      >
        <Form.Item name="orgField" label="单位类型(多选):" labelAlign="left">
          <Radio.Group>
            <Row style={{ height: "38px" }}>
              <Radio value="集成电路">集成电路</Radio>
              <Radio value="生物医药">生物医药</Radio>
              <Radio value="人工智能">人工智能</Radio>
              <Radio value="人工智能">人工智能</Radio>
              <Radio value="人工智能">人工智能</Radio>
              <Radio value="集成电路">集成电路</Radio>
              <Radio value="生物医药">生物医药</Radio>
              <Radio value="人工智能">人工智能</Radio>
              <Radio value="人工智能">人工智能</Radio>
              <Radio value="j">
                <Input style={{ width: 140 }} placeholder="其他" />
              </Radio>
            </Row>
          </Radio.Group>
        </Form.Item>

        <Form.Item style={{ marginBottom: 0 }}>
          <Form.Item
            name="worksName"
            validateTrigger={["onChange", "onBlur"]}
            rules={[
              {
                required: true,
                whitespace: true,
                message: "参赛项目名称不能为空.",
              },
            ]}
            style={{ display: "inline-block", width: 240, marginTop: "40px" }}
          >
            <Input placeholder="专利组合/项目名称（多个专利时填写）" />
          </Form.Item>
        </Form.Item>

        <Form.List
          name="patents"
          initialValue={[{}]}
          rules={[
            {
              validator: async (_, names) => {
                if (!names || names.length < 1) {
                  return Promise.reject(new Error("At least 1 passengers"));
                }
              },
            },
          ]}
        >
          {(fields, { add, remove }, { errors }) => (
            <>
              {fields.map(({ key, name, ...restField }) => (
                <Space
                  key={key}
                  style={{ display: "flex", marginBottom: 1 }}
                  align="baseline"
                >
                  <Form.Item
                    {...restField}
                    name={[name, "no"]}
                    style={{ display: "inline-block", width: 200 }}
                    rules={[{ required: true, message: "专利号不能为空" }]}
                  >
                    <Input placeholder="专利号" />
                  </Form.Item>
                  <Form.Item
                    {...restField}
                    name={[name, "name"]}
                    style={{ display: "inline-block", width: 200 }}
                    rules={[{ required: true, message: "专利名称不能为空" }]}
                  >
                    <Input placeholder="专利名称" />
                  </Form.Item>
                  <Form.Item
                    {...restField}
                    name={[name, "users"]}
                    style={{ display: "inline-block", width: 200 }}
                    rules={[{ required: true, message: "专利权益人" }]}
                  >
                    <Input placeholder="专利权益人" />
                  </Form.Item>
                  <MinusCircleOutlined onClick={() => remove(name)} />
                </Space>
              ))}
              <Form.Item>
                <Button
                  type="dashed"
                  onClick={() => add()}
                  style={{ width: "60%" }}
                  icon={<PlusOutlined />}
                >
                  添加专利
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>

        {/* add row */}
        {/* {unitNames.map((field, index) => {
          return (
            <Form.Item>
              <Form.Item style={{ marginBottom: 0 }}>
                <Form.Item
                  name="contact"
                  rules={[{ required: true }]}
                  style={{ display: "inline-block", width: 200 }}
                >
                  <Input placeholder="专利号" />
                </Form.Item>
                <Form.Item
                  name="phone"
                  rules={[{ required: true }]}
                  style={{
                    display: "inline-block",
                    width: 200,
                    margin: "0 8px",
                  }}
                >
                  <Input placeholder="专利名称" />
                </Form.Item>
                <Form.Item
                  name="guider"
                  rules={[{ required: true }]}
                  style={{
                    display: "inline-block",
                    width: 200,
                    margin: "0 8px",
                  }}
                >
                  <Input placeholder="专利权人" />
                </Form.Item>

                {unitNames.length > 1 ? (
                  <Form.Item
                    name="del"
                    rules={[{ required: true }]}
                    style={{
                      display: "inline-block",
                      width: 40,
                      margin: "0 8px",
                    }}
                  >
                    <MinusCircleOutlined
                      className="dynamic-delete-button"
                      onClick={() => remove(index)}
                    />
                  </Form.Item>
                ) : null}
              </Form.Item>
            </Form.Item>
          );
        })}

        <Form.Item style={{ marginTop: "-20px" }}>
          <Button
            type="dashed"
            onClick={() => add()}
            style={{ width: "60%" }}
            icon={<PlusOutlined />}
          >
            添加专利
          </Button>
        </Form.Item> */}

        {/*tel row*/}

        <Form.Item style={{ marginBottom: 0, marginTop: "20px" }}>
          <Form.Item
            name="contactUser"
            validateTrigger={["onChange", "onBlur"]}
            rules={[
              { required: true, whitespace: true, message: "联系人不能为空." },
            ]}
            style={{ display: "inline-block", width: 240 }}
          >
            <Input placeholder="联系人" />
          </Form.Item>
          <Form.Item
            name="contactPhone"
            validateTrigger={["onChange", "onBlur"]}
            rules={[
              { required: true, whitespace: true, message: "电话不能为空." },
            ]}
            style={{ display: "inline-block", width: 240, margin: "0 8px" }}
          >
            <Input placeholder="电话" />
          </Form.Item>
        </Form.Item>

        <div className="subTitle-zh" style={{ marginTop: "50px" }}>
          技术先进性
        </div>
        {/* row 1*/}

        <Row gutter={[16, 16]} style={{ marginTop: "25px" }}>
          <Col span={"9"}>
            <label className="form-label">
              技术研发背景简介：说明该专利（专利组合/项目）技术的研发初衷，以及在现行趋势下同类技术的技术优势；若为专利组合/项目，请列举专利组合或项目内的专利名称及专利号。
            </label>
          </Col>
          <Col span={"9"} offset={1}>
            <label className="form-label">
              技术内容简介：简要介绍技术方案并简要说明创造性（创新点）形成的积极意义。
            </label>
          </Col>
        </Row>

        <Row gutter={[16, 16]} style={{ marginTop: "15px" }}>
          <Col span={"9"}>
            <Form.Item noStyle name="coreTechnology">
              <Input.TextArea rows={4} />
            </Form.Item>
          </Col>

          <Col span={"9"} offset={1}>
            <Form.Item noStyle name="advancedTechnology">
              <Input.TextArea rows={4} />
            </Form.Item>
          </Col>
        </Row>

        {/* row 2*/}
        <Form.Item
          name="patentStatus"
          label="专利技术成果转化情况"
          labelAlign="left"
          className="formItem-label"
          style={{ marginTop: "25px" }}
        >
          <Radio.Group style={{ paddingLeft: "40px" }}>
            <Radio value={1} style={{ color: "#1e43ad" }}>
              已转化
            </Radio>
            <Radio value={0} style={{ color: "#1e43ad" }}>
              未转化
            </Radio>
          </Radio.Group>
        </Form.Item>

        <Row gutter={[16, 16]}>
          <Col span={"9"}>
            <label className="form-label">
              专利权人为促进专利价值实现，在未来将如何加快专利的有效实施、与企业研发和营销的有机相结合、提升市场竞争力。
            </label>
          </Col>
        </Row>

        <Row gutter={[16, 16]} style={{ marginTop: "15px" }}>
          <Col span={"9"}>
            <Form.Item noStyle name="patentValue">
              <Input.TextArea rows={4} />
            </Form.Item>
          </Col>
        </Row>

        {/* row 3*/}
        <div className="subTitle-zh" style={{ marginTop: "50px" }}>
          社会效益及发展前景评价材料
        </div>
        <Row gutter={[16, 16]} style={{ marginTop: "25px" }}>
          <Col span={"9"}>
            <label className="form-label">
              社会效益状况：说明参评项目对社会产生的积极作用。
            </label>
          </Col>
          <Col span={"9"} offset={1}>
            <label className="form-label">
              行业影响力状况：说明参评项目实施对行业发展及技术趋势的影响。
            </label>
          </Col>
        </Row>

        <Row gutter={[16, 16]} style={{ marginTop: "15px" }}>
          <Col span={"9"}>
            <Form.Item noStyle name="socialResults">
              <Input.TextArea rows={4} />
            </Form.Item>
          </Col>

          <Col span={"9"} offset={1}>
            <Form.Item noStyle name="patentEffect">
              <Input.TextArea rows={4} />
            </Form.Item>
          </Col>
        </Row>

        {/* row 4*/}
        <Row gutter={[16, 16]} style={{ marginTop: "50px" }}>
          <Col span={"9"}>
            <label className="form-label">
              政策适应性：说明参评项目属于国家政策明确鼓励、支持的，还是限制、禁止类别，或无明确导向。
            </label>
          </Col>
        </Row>

        <Row gutter={[16, 16]} style={{ marginTop: "15px" }}>
          <Col span={"9"}>
            <Form.Item noStyle name="policyAdaptability">
              <Input.TextArea rows={4} />
            </Form.Item>
          </Col>
        </Row>

        {/* row 5*/}
        <div className="subTitle-zh" style={{ marginTop: "50px" }}>
          获奖状况
        </div>
        <Row gutter={[16, 16]} style={{ marginTop: "15px" }}>
          <Col span={"9"}>
            <label className="form-label">
              简要列出参评专利何时何地获何种等级的奖励及其颁奖单位等情况供参考
            </label>
          </Col>
        </Row>

        <Row gutter={[16, 16]} style={{ marginTop: "15px" }}>
          <Col span={"9"}>
            <Form.Item noStyle name="awardStatus">
              <Input.TextArea rows={4} />
            </Form.Item>
          </Col>
        </Row>

        {/*        <button
          type="button"
          className="blueButton"
          onClick={submitForm}
          style={{ marginTop: "35px", marginBottom: "50px" }}
        >
          提交报名信息
        </button>*/}
        <button
          type="button"
          className="blueButton"
          onClick={showTip}
          style={{ marginTop: "35px", marginBottom: "50px" }}
        >
          提交报名信息
        </button>
      </Form>
    </div>
  );
};

export default College;
