import React, { useState, useEffect } from "react";
import SHMap from "./shanghai-map";

export interface MapProps {
  shNum: number;
  areaList: Array<{ area: string; progress: string; value: number }>;
}
const MapChart: React.FC<MapProps> = (props) => {
	const areaList = props.areaList || [];
	var maxVal = 0;
	var activeArea = "";
	for (var i = 0; i < areaList.length; i++) {
		var _obj = areaList[i];
		if (_obj.value > maxVal) {
			maxVal = _obj.value
			activeArea = _obj.area
		}
	}
	
  const [active, setActive] = useState(activeArea);
  const setActiveArea = (areaName: string) => {
    setActive(areaName) ;
  };
  
  return (
    <div className="map-div-container dashDashboard-block">
      <div className="map-div-title">上海专利申请数量</div>
      <div className="map-div-num">{props.shNum}</div>
      <div className="map-div-svgContainer">
        <SHMap areaChange={(area: string) => setActiveArea(area)} areaActive={activeArea as string} />

        <div className="map-div-barChart">
          {props.areaList.map((item, index) => {
            return (
              <div
                key={index}
                className={
                  item.area === active
                    ? "barChart-div-bar active"
                    : "barChart-div-bar"
                }
                style={{ height: item.progress }}
              >
                <div className="bar-div-data">{item.value}</div>
                <div className="bar-div-title">{item.area}</div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default MapChart;
