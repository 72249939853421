import React from "react";
import { Route, Switch } from "react-router-dom";

import Jury from "../activity/jury";
import Notice from "../activity/notice/notice";
import ActivityDetail from "../activity/detail";
import Enterprise from "../activity/enter/enterprise";
import College from "../activity/enter/college";

const ActivityRouter = () => {
  return (
    <Switch>
      {/*<Route exact path="/" component={Home} />*/}
      <Route exact path="/match/" component={ActivityDetail} />
      <Route exact path="/match/detail" component={ActivityDetail} />
      <Route exact path="/match/jury" component={Jury} />
      <Route exact path="/match/notice" component={Notice} />
      <Route exact path="/match/enterprise" component={Enterprise} />
      <Route exact path="/match/college" component={College} />
    </Switch>
  );
};

export default ActivityRouter;
