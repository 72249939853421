import { Upload } from "antd";
import BaseService from "../core/base-service";
import StorageService, { StorageItems } from "../core/storage-service";

export default class DataService extends BaseService {
  // TODO: change this to your route in server
  static route = "data";
  private storage: StorageService = new StorageService();

  /*专利列表接口*/
  async getCityData() {
    const url = `/api/third/getAreaList`;

    const response = await this.httpService.get<any>(url);
    return response;
  }

  async getDataDetail(random: string, id: string) {
    /// http://106.14.113.242:8001/api/third/getDataDetail?id=202003051583349087390&random=202207111657542319389
    const url = `/api/third/getDataDetail?id=${id}&random=${random}`;
    const response = await this.httpService.get<any>(url);
    return response;
  }
  async getDataQushi() {
    /// http://106.14.113.242:8001/api/third/getDataDetail?id=202003051583349087390&random=202207111657542319389
    const url = `/api/third/getDataAllQuShi`;
    const response = await this.httpService.get<any>(url);
    return response;
  }

  async getYPData() {
    const url = process.env.NODE_ENV === "development" ? "http://139.224.231.198:8080/api/third/getYangPuDataDetail"
        : '/api/third/getYangPuDataDetail';
    const response = await this.httpService.get<any>(url);
    return response;
  }


}
