import BaseService from "../core/base-service";
import StorageService, { StorageItems } from "../core/storage-service";

export default class ActivityService extends BaseService {
  // TODO: change this to your route in server
  static route = "activity";
  private storage: StorageService = new StorageService();

  async getNoticeData(pageNo = 1) {
    const url = `/api/patent/movement?page=${pageNo}&size=10`;
    const response = await this.httpService.get<any>(url);
    return response;
  }

  /* 获取报名详情 */
  async getWorksDetail() {
    const url = `/api/c/patent/works/detail`;
    const response = await this.httpService.get<any>(url);
    return response;
  }
}
